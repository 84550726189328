import CloseIcon from "@mui/icons-material/Close";
import { BucketTypes, ModalTabs, PaymentMethod } from "constants/index";
import { ModalType, useModal, useWallet } from "contexts";
import {
  FundingStatus,
  UserFundingStatus,
  useProjectIdo,
} from "contexts/ProjectIdoContext";
import { useEffect, useState } from "react";

import styles from "./tmodal.module.scss";
import { CloseWindow } from "pages/Modal/components/closeWindow/CloseWindow";
import { Button, Typography } from "@material-ui/core";
import Chip from "@mui/material/Chip";
import axios from "axios";

// interface ITransactionModalProps {
//   transactions: FundingStatus[];
//   slug: string;
// }

export const TransactionModal = () => {
  const [closeWindow, setCloseWindow] = useState<boolean>(false);
  const {
    [ModalType.TRANSACTIONS]: transactionsModalOpen,
    // transactionsModalTab,
    closeModal,
    openModal,
  } = useModal();
  // const [tab, setRealTab] = useState<ModalTabs>(
  //   transactionsModalTab || ModalTabs.Bucket
  // );
  const [txId, setTxId] = useState("");
  const [selectedBucket, setSelectedBucket] = useState<BucketTypes>(
    BucketTypes.Bronze
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null
  );
  const [amountSatoshi, setAmountSatoshi] = useState<string>("");
  const {
    Gold,
    Silver,
    Mercury,
    fundingStatus,
    fundingStati,
    slug,
    isTransactions,
    tier,
    checkFundingStatus,
  } = useProjectIdo();

  useEffect(() => {
    checkFundingStatus();
  }, [transactionsModalOpen]);

  // if (!transactionsModalOpen) {
  //   return null;
  // }

  // if (!transactionsModalTab) {
  //   return null;
  // }

  function shortenTransactionHash(
    hash: string,
    prefixLength = 3,
    suffixLength = 3
  ) {
    if (typeof hash !== "string" || hash.length < prefixLength + suffixLength) {
      return hash;
    }

    const prefix = hash.substring(0, prefixLength);
    const suffix = hash.substring(hash.length - suffixLength);

    return `${prefix}...${suffix}`;
  }

  function convertISODateToDate(isoDateString: string): string {
    const date = new Date(isoDateString);
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toUTCString().replace(/ GMT$/, "");
  }

  return (
    <div className={styles.main}>
      {closeWindow === true ? (
        <CloseWindow setCloseWindow={setCloseWindow} resetTab={() => {}} />
      ) : (
        false
      )}
      <div className={styles.inside}>
        <div className={styles.content}>
          <h1 className={styles.title}>Transactions for this project</h1>
          <div className={styles.transactionsList}>
            {fundingStati
              ?.sort((a: any, b: any) => {
                return (
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
                );
              })
              .map((item, index) => {
                return (
                  <div className={styles.oneTransaction} key={index}>
                    <div className={styles.firstRow}>
                      <Typography className={styles.txChip}>
                        Transaction Tier: {item.bucketSize}
                      </Typography>
                      <Typography className={styles.txChip}>
                        Tokens Bought: {item.deposit}
                      </Typography>

                      <Typography className={styles.txChip}>
                        Status:{" "}
                        <Chip
                          sx={{
                            minWidth: "100px",
                            marginLeft: "10px",
                          }}
                          label={
                            item.status === UserFundingStatus.SUCCESS
                              ? "LOCKED"
                              : item.status ===
                                UserFundingStatus.PAID_FROM_ORDINALS
                              ? "PAYMENT CONFIRMED"
                              : item.status === UserFundingStatus.PAID_TOO_LESS
                              ? "UNDERPAID"
                              : item.status === UserFundingStatus.PAID
                              ? "PAYMENT INITIATED"
                              : "EXPIRED"
                          }
                          color={
                            item.status === UserFundingStatus.SUCCESS
                              ? "warning"
                              : item.status ===
                                UserFundingStatus.FAILURE_PAID_TOO_LESS
                              ? "error"
                              : "success"
                          }
                          variant="outlined"
                        />
                      </Typography>
                    </div>
                    {item.txn_hash ? (
                      <div className={styles.secondRow}>
                        <Typography>
                          Txn Hash:{" "}
                          <span
                            className={styles.txHash}
                            onClick={() => {
                              window.open(
                                `https://mempool.space/tx/${item.txn_hash}`,
                                "_blank"
                              );
                            }}
                          >
                            {shortenTransactionHash(item.txn_hash)}
                          </span>
                        </Typography>
                        <Typography className={styles.time}>
                          {" "}
                          {convertISODateToDate(item.createdAt)}
                        </Typography>
                      </div>
                    ) : (
                      <div className={styles.secondRow}>
                        <span>Payment not processed yet</span>
                        <Typography className={styles.time}>
                          {convertISODateToDate(item.createdAt)}
                        </Typography>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <div className={styles.bottomButtons}>
          <Button
            className={`${styles.btn} ${styles.leftBtn}`}
            onClick={() => {
              closeModal(ModalType.TRANSACTIONS);
              setCloseWindow(false);
            }}
          >
            <Typography>Back</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
