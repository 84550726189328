import { ApiMessages, Messages } from "constants/index";
import { useSignMessage } from "hooks";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { RegisterUserRequest } from "services/types";
import {
  getDepositAddressService,
  registerUserService,
} from "services/userService";

export interface WalletData {
  paymentAddress: string | null;
  paymentPublicKey: string | null;
  ordinalsAddress: string | null;
  ordinalsPublicKey: string | null;
  walletName: string;
}

interface RegisteredUserData {
  withdrawAddress: string;
  depositAddress: string;
}

interface WalletContextType {
  walletData: WalletData | null;
  connectWallet: (data: WalletData | null) => void;
  disconnectWallet: () => void;
  registeredUserData: RegisteredUserData | null;
  lastTx: string;
  ready: boolean;
  setLastTx: (tx: string) => void;
  transaction_id: string;
  setTransactionId: (tx: string) => void;
  networkFee: number;
  setNetworkFee: (fee: number) => void;
  tokensBought: number;
  setTokensBought: (tokens: number) => void;
  additionalFee: number;
  setAdditionalFee: (fee: number) => void;
  chainFee: number;
  setChainFee: (fee: number) => void;
  fee: number;
  setFee: (fee: number) => void;
  orderTotal: number;
  setOrderTotal: (total: number) => void;
}

export const WalletContext = createContext<WalletContextType>({
  walletData: null,
  connectWallet: (data: WalletData | null) => {
    console.log(`Default connectWallet invoked with ${data}`);
  },
  disconnectWallet: () => {
    console.log("Default disconnectWallet invoked");
  },
  registeredUserData: null,
  lastTx: "",
  ready: false,
  setLastTx: (tx: string) => {
    console.log(`Default setLastTx invoked with ${tx}`);
  },
  transaction_id: "",
  setTransactionId: (tx: string) => {
    console.log(`Default setTransactionId invoked with ${tx}`);
  },
  networkFee: 1,
  setNetworkFee: (fee: number) => {
    console.log(`Default setNetworkFee invoked with ${fee}`);
  },
  tokensBought: 0,
  setTokensBought: (tokens: number) => {
    console.log(`Default setTokensBought invoked with ${tokens}`);
  },
  additionalFee: 0,
  setAdditionalFee: (fee: number) => {
    console.log(`Default setAdditionalFee invoked with ${fee}`);
  },
  chainFee: 0,
  setChainFee: (fee: number) => {
    console.log(`Default setChainFee invoked with ${fee}`);
  },
  fee: 0,
  setFee: (fee: number) => {
    console.log(`Default setFee invoked with ${fee}`);
  },
  orderTotal: 0,
  setOrderTotal: (total: number) => {
    console.log(`Default setOrderTotal invoked with ${total}`);
  },
});

export const WalletProvider: React.FC = ({ children }) => {
  const walletDataRef = useRef<WalletData | null>(null);
  const { signMessage } = useSignMessage(walletDataRef);
  const [walletData, setWalletData] = useState<WalletData | null>(null);
  const [lastTx, setLastTx] = useState<string>("");
  const [ready, setReady] = useState<boolean>(false);
  const [transaction_id, setTransactionId] = useState<string>("");
  const [networkFee, setNetworkFee] = useState<number>(1);
  const [tokensBought, setTokensBought] = useState<number>(0);
  const [additionalFee, setAdditionalFee] = useState<number>(0);
  const [chainFee, setChainFee] = useState<number>(0);
  const [fee, setFee] = useState<number>(0);
  const [orderTotal, setOrderTotal] = useState<number>(0);
  const [registeredUserData, setRegisteredUserData] =
    useState<RegisteredUserData | null>(null);

  useEffect(() => {
    walletDataRef.current = walletData;
  }, [walletData]);

  const connectWallet = (data: WalletData) => setWalletData(data);

  const disconnectWallet = () => {
    localStorage.removeItem("connected-wallet");
    localStorage.removeItem("registered-user");
    setRegisteredUserData(null);
    return setWalletData(null);
  };

  useEffect(() => {
    const localWallet = localStorage.getItem("connected-wallet");
    console.log("localWallet", localWallet);

    if (localWallet) {
      setWalletData(JSON.parse(localWallet));
    }
    setReady(true);
  }, []);

  // register user
  useEffect(() => {
    const handleRegistration = async () => {
      if (walletData && walletData.ordinalsAddress && !registeredUserData) {
        // first check if not registered already
        const savedRegisteredUserData: RegisteredUserData | null =
          await getDepositAddressService(walletData.ordinalsAddress);
        // if not registered, register the user, if registered check funding status
        if (savedRegisteredUserData) {
          setRegisteredUserData(savedRegisteredUserData);
        } else {
          const signature = await signMessage(Messages.REGISTER);
          if (signature) {
            const requestBody: RegisterUserRequest = {
              public: walletData.ordinalsPublicKey || "",
              address: walletData.ordinalsAddress || "",
              msg: ApiMessages.REGISTER,
              sign: signature,
              walletType: walletData.walletName.toLowerCase() || "",
            };
            const result: RegisteredUserData = await registerUserService(
              requestBody
            );
            setRegisteredUserData(result);
          } else {
            // if registration canceled or failed, disconnect wallet
            disconnectWallet();
          }
        }
      }
    };
    // handleRegistration();
  }, [walletData, registeredUserData, signMessage]);

  useEffect(() => {
    const localUser = localStorage.getItem("registered-user");
    if (localUser) {
      setRegisteredUserData(JSON.parse(localUser));
    }
  }, []);

  useEffect(() => {
    if (walletData) {
      console.log("walletData changed", walletData);
      localStorage.setItem("connected-wallet", JSON.stringify(walletData));
    }
  }, [walletData]);

  useEffect(() => {
    if (registeredUserData) {
      localStorage.setItem(
        "registered-user",
        JSON.stringify(registeredUserData)
      );
    }
  }, [registeredUserData]);

  return (
    <WalletContext.Provider
      value={{
        walletData,
        connectWallet,
        disconnectWallet,
        registeredUserData,
        lastTx,
        setLastTx,
        ready,
        transaction_id,
        setTransactionId,
        networkFee,
        setNetworkFee,
        tokensBought,
        setTokensBought,
        additionalFee,
        setAdditionalFee,
        chainFee,
        setChainFee,
        fee,
        setFee,
        orderTotal,
        setOrderTotal,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
