import { Flex, Text } from "components";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";

import "./_tiertimer.scss";

type TimerProps = {
  isoTime: string;
  timerStatus: string;
};

export const TierTimer: React.FunctionComponent<TimerProps> = ({
  isoTime,
  timerStatus,
}) => {
  const [refresh, setRefresh] = useState(false);
  const { seconds, minutes, hours, days, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      setRefresh((t) => !t);
      // window.location.reload();
    },
  });

  useEffect(() => {
    const time = new Date(isoTime ? isoTime.toString() : "");
    restart(time);
  }, [restart, refresh, isoTime]);

  return (
    <Flex className="tiertimer-cont">
      <Text size="xs" color="grey6">
        {timerStatus}
      </Text>

      <Flex className="tiertimer">
        <RemainingTime value={days} unit="D" />
        <RemainingTime value={hours} unit="H" />
        <RemainingTime value={minutes} unit="M" />
        <RemainingTime value={seconds} unit="S" />
      </Flex>
    </Flex>
  );
};

type RemainingTimeProps = {
  value: number;
  unit: string;
};

const RemainingTime = ({ value, unit }: RemainingTimeProps) => (
  <Flex className="tier-remaining-time">
    <Text className="tier-time-values" size="lg">
      {value > 9 ? value : `0${value}`} {unit}
    </Text>
    {/* <Text className="tier-time-units" size="xxs">
      {unit}
    </Text> */}
  </Flex>
);
