import { Button, Typography } from "@mui/material";
import { ModalType, useModal } from "contexts";

import styles from "./Blocked.module.scss";
import { Modal } from "@material-ui/core";

export const BlockedWindow = (props: {
  setCloseWindow?: any;
  resetTab?: any;
}) => {
  const { closeModal } = useModal();
  return (
    <div className={styles.main}>
      <div
        className={styles.inside}
        onClick={() => {
          // props.setCloseWindow(false);
        }}
      >
        <Typography className={styles.title}>
          {"We're sorry, but you are not allowed to use Toshipad"}
        </Typography>
        <Typography className={styles.desc}>
          Your IP is from a restricted region where Toshipad does not provide
          services or products. Please confirm that you are not a citizen or
          resident of the United States or the United Kingdom.
        </Typography>
        <div className={styles.bottomButtons}>
          <Button
            className={`${styles.btn} ${styles.rightBtn}`}
            onClick={() => {
              closeModal(ModalType.BLOCKED);
              // setBlockedModalOpen(false);

              // props.resetTab();
            }}
          >
            <Typography>I confirm</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
