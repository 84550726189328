import { ModalType, useModal } from "contexts";
import { FC, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { Footer, Topbar, WalletConnect, WalletDisconnect } from "./components";

import "./_layout.scss";
import { Modal } from "@material-ui/core";
import { TransactionModal } from "pages/TransactionsModal";
import { ProjectIdoProvider, useProjectIdo } from "contexts/ProjectIdoContext";
import { BlockedWindow } from "./components/BlockedPopup/Blocked";
import { ModalTabs } from "constants/index";

interface ILayoutProps {}

const paramsRoutes = ["stake", "project"];
const specialRoutesRoutes = ["squadrons", "leaderboard"];

export const Layout: FC<ILayoutProps> = (props) => {
  const { children } = props;
  const location = useLocation();
  const {
    [ModalType.WALLET_CONNECT]: walletConnectModalOpen,
    [ModalType.WALLET_DISCONNECT]: walletDisconnectModalOpen,
    [ModalType.TRANSACTIONS]: transactionsModalOpen,
    [ModalType.BLOCKED]: blockedModalOpen,
    paymentModalTab,
  } = useModal();
  const [tab, setRealTab] = useState<ModalTabs>(
    paymentModalTab || ModalTabs.BucketConfirmSelection
  );
  const [, pageRoute, params, additional, special] =
    location.pathname.split("/");
  let layoutClass = pageRoute || "homepage";

  if (paramsRoutes.find((pr) => pr === pageRoute) && params) {
    layoutClass = params;
  }

  if (additional) {
    if (specialRoutesRoutes.find((pr) => pr === additional) && special) {
      layoutClass = `${additional}-${special}`;
    } else {
      layoutClass = additional;
    }
  }

  const rootClass = "layout";

  useEffect(() => {
    console.log(transactionsModalOpen, "tro open");
  }, [transactionsModalOpen]);

  return (
    <div className={`${rootClass} ${layoutClass}`}>
      <Switch>
        <Route>
          <div className={`${rootClass}__topbar-wrapper`}>
            <Topbar activeTab={pageRoute} />
          </div>
        </Route>
      </Switch>

      <div className={`${rootClass}__content-wrapper`}>{children}</div>
      <Switch>
        <Route>
          <div className={`${rootClass}__footer-wrapper`}>
            <Footer />
          </div>
        </Route>
      </Switch>

      {walletConnectModalOpen && <WalletConnect />}

      {walletDisconnectModalOpen && <WalletDisconnect />}

      {blockedModalOpen && <BlockedWindow />}

      {/* {transactionsModalOpen && <TransactionModal />} */}
    </div>
  );
};
