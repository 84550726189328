import styles from "./getting-started.module.scss";

export const GettingStarted = () => {
  return (
    <div className={styles.gsBlock}>
      <div className={styles.gsTitle}>Getting Started</div>
      <div className={styles.cardsRow}>
        <div className={styles.card}>
          <div className={styles.cardTitle}>Builders</div>
          <ul
            style={{
              listStyle: "disc",
            }}
          >
            <li>
              Explore exciting opportunities for your innovative ideas by
              applying to our grants program and turning them into reality.
            </li>
            <li>
              Drive sustained success by seamlessly incorporating TSHX into your
              projects for long-term growth.
            </li>
            <li>
              Elevate your project to new heights with our incubation services,
              leveraging our extensive network, marketing expertise, tokenomics,
              and consulting skills.
            </li>
          </ul>
        </div>
        <div className={styles.card}>
          <div className={styles.cardTitle}>Users</div>
          <ul
            style={{
              listStyle: "disc",
            }}
          >
            <li>
              Hold TSHX tokens to get access to the most highly vetted projects
              in Ordinals.
            </li>
            <li>
              5 tiers of access aligning with the chief alchemical metals: Gold,
              Silver, Mercury, Copper, Lead
            </li>
            <li>
              Access higher tiers by being a Top Holder. Higher tiers receive
              higher allocations and early access to launched projects
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
