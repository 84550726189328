import axios from "axios";

const getBTCPrice = async () => {
  if (
    localStorage.getItem("BTCPrice") &&
    Number(localStorage.getItem("BTCPriceDate")) + 10000 > Date.now()
  ) {
    return localStorage.getItem("BTCPrice");
  }
  const {
    data: {
      bitcoin: { usd },
    },
  } = await axios.get(
    `https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd`
  );
  localStorage.setItem("BTCPrice", usd);
  localStorage.setItem("BTCPriceDate", `${Date.now()}`);
  return usd;
};

export const btcToDollar = async (amount: string) => {
  const btcPrice = await getBTCPrice();
  console.log("amount passed to conversion function", amount);
  console.log("btcPrice", btcPrice);
  console.log((Number(amount) * Number(btcPrice)).toFixed(2), "btcToDollar");
  return `$${(Number(amount) * Number(btcPrice)).toFixed(2).toString()}`;
};
