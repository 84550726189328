import { Button, Typography } from "@mui/material";
import { Icons, Images } from "assets";

import { ModalType, WalletData, useModal, useWallet } from "contexts";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { shortenAddress } from "utils/ShortenAddress";

import { useWindowSize } from "hooks/useWindowSize.js";

import { Notis } from "../Notis";

import styles from "./topbar.module.scss";
import axios from "axios";
import { useProjectIdo } from "contexts/ProjectIdoContext";
import { open } from "fs";
import { off } from "process";

function OutsideClickCheck(ref: any, visible: any, changeVisible: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        visible === true
      ) {
        changeVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, visible, changeVisible]);
}

export const Topbar = (props: { activeTab: any }) => {
  const size: any = useWindowSize();
  const [openedMobile, setOpenedMobile] = useState<boolean>(false);
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const { walletData } = useWallet();
  const { openModal, [ModalType.BLOCKED]: blockedModalOpen } = useModal();

  const { tier } = useProjectIdo();

  const [openedNoti, setOpenedNoti] = useState<boolean>(false);
  const notiRef = useRef(null);
  OutsideClickCheck(notiRef, openedNoti, setOpenedNoti);

  const [cnclBalance, setCnclBalance] = useState<number>(0);
  const [txhxBalance, setTxhxBalance] = useState<number>(0);
  const [ipAddress, setIPAddress] = useState("");
  const [blocked, setBlocked] = useState(true);

  const handleWalletBalance = async (addr: string) => {
    console.log(tier, "TIER");
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND}/users/balance?wallet_address=${addr}`
    );
    console.log(
      res.data.data,
      res.data.data.cncl_balance,
      res.data.data.tshx_balance,
      "balance from topbar"
    );
    setCnclBalance(res.data.data.cncl_balance);
    setTxhxBalance(res.data.data.tshx_balance);
  };

  useEffect(() => {
    console.log(walletData, "walletData from topbar ue");
    if (walletData) {
      setWalletAddress(
        shortenAddress(
          walletData.ordinalsAddress
            ? walletData.ordinalsAddress
            : walletData.paymentAddress || ""
        )
      );
      handleWalletBalance(
        walletData.ordinalsAddress
          ? walletData.ordinalsAddress
          : walletData.paymentAddress || ""
      );
    } else {
      setWalletAddress(null);
    }
  }, [walletData]);

  // useEffect(() => {
  //   if (blockedModalOpen) {
  //     openModal(ModalType.BLOCKED);
  //   }
  // }, [blockedModalOpen]);

  useEffect(() => {
    // setInterval(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "ip data");
        setIPAddress(data.ip);
        return data.ip;
      })
      .then((ip) => {
        fetch(`https://api.iplocation.net/?ip=${ip}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.country_code2 === "US" || data.country_code2 === "GB") {
              setBlocked(true);
              console.log("blocked from topbar", data);
              openModal(ModalType.BLOCKED);
              // setBlockedModalOpen(true);
            }
          });
      })
      .catch((error) => console.log(error));
    // }, 1000);
  }, []);

  // useEffect(() => openModal(ModalType.BLOCKED), [blocked]);

  return (
    <header
      className={
        size.width > 1070
          ? `${styles.nav}`
          : openedMobile === true
          ? `${styles.nav} ${styles.mobileNav}`
          : `${styles.nav} ${styles.mobileNavHidden}`
      }
    >
      <Link to="/">
        <img src={Images.toshipadLogo} className={styles.logo} alt="ToshiPad" />
      </Link>
      <div
        className={styles.burger}
        onClick={() => {
          setOpenedMobile(!openedMobile);
        }}
      >
        <img src={Icons.burger} alt="Menu" />
      </div>
      <div
        className={
          size.width > 1070
            ? `${styles.center}`
            : openedMobile === true
            ? `${styles.center} ${styles.centerMobile}`
            : `${styles.center}`
        }
      >
        <Link
          to="/"
          onClick={() => setOpenedMobile(false)}
          data-value={props.activeTab === "" ? "active" : ""}
        >
          Home
        </Link>
        <Link
          to="/projects"
          onClick={() => setOpenedMobile(false)}
          data-value={
            props.activeTab === "projects" || props.activeTab === "project"
              ? "active"
              : ""
          }
        >
          Projects
        </Link>
        {/* <Link
          to="/how-it-works"
          onClick={() => setOpenedMobile(false)}
          data-value={props.activeTab === "how-it-works" ? "active" : ""}
        >
          How it works
        </Link> */}

        <a
          className={styles.rightBtn}
          href="https://the-council.gitbook.io/the-council/the-council/core-products/toshipad"
          target="_blank"
        >
          Docs
        </a>
        <a
          className={styles.rightBtn}
          href="https://medium.com/@Toshipad"
          target="_blank"
        >
          Blog
        </a>

        {/* <Button
          className={styles.rightBtn}
          href="https://discord.com/invite/ordinalscouncil"
          target="_blank"
        >
          <img src={Icons.Socials.discord} alt="Discord" />
        </Button>
        <Button
          className={styles.rightBtn}
          href="https://t.me/+9eED1XpNuuNiOGM1"
          target="_blank"
        >
          <img src={Icons.Socials.telegram} alt="Telegram" />
        </Button>
        <Button
          className={styles.rightBtn}
          href="https://twitter.com/toshipadcom"
          target="_blank"
        >
          <img src={Icons.Socials.twitter} alt="Twitter" />
        </Button> */}
      </div>
      {/* <div className={styles.rightBtns}>
        <Button
          className={styles.rightBtn}
          href="https://discord.com/invite/ordinalscouncil"
          target="_blank"
        >
          <img src={Icons.Socials.discord} alt="Discord" />
        </Button>
        <Button
          className={styles.rightBtn}
          href="https://t.me/+9eED1XpNuuNiOGM1"
          target="_blank"
        >
          <img src={Icons.Socials.telegram} alt="Telegram" />
        </Button>
        <Button
          className={styles.rightBtn}
          href="https://twitter.com/toshipadcom"
          target="_blank"
        >
          <img src={Icons.Socials.twitter} alt="Twitter" />
        </Button>
      </div> */}
      <div
        className={
          size.width > 1070
            ? `${styles.right}`
            : openedMobile === true
            ? `${styles.right} ${styles.rightMobile}`
            : `${styles.right}`
        }
      >
        {/* <div
          className={styles.noti}
          onClick={() => {
            setOpenedNoti(!openedNoti)
          }}>
          <div className={styles.icon}>
            <img src={Icons.bell} alt="Bell" />
            <span>/!*ORANGE DOT*!/ </span>
          </div>
          <Typography>2 New</Typography>
        </div> */}
        {walletAddress ? (
          <>
            <Button
              className={styles.wallet}
              onClick={() => openModal(ModalType.WALLET_DISCONNECT)}
            >
              <div className={styles.balance}>
                <img
                  src={Icons.Tokens.cncl}
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
                <Typography>{Math.round(cnclBalance)}</Typography>
                <img
                  src={Icons.Tokens.tshx}
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
                <Typography>{Math.round(txhxBalance)}</Typography>
              </div>

              <span className={styles.icon}>
                <img src={Icons.naviWallet} alt="" />
              </span>
              <Typography>{walletAddress}</Typography>
              <span className={styles.icon}>
                <img src={Icons.arrowDown} alt="" />
              </span>
            </Button>
          </>
        ) : (
          <Button
            className={styles.wallet}
            onClick={() => openModal(ModalType.WALLET_CONNECT)}
          >
            <span className={styles.icon}>
              <img src={Icons.naviWallet} alt="" />
            </span>
            <Typography>Connect wallet</Typography>
          </Button>
        )}
        {openedNoti === true ? (
          <div ref={notiRef} className={styles.notis}>
            <Notis />
            <Button
              className={styles.notiBtn}
              onClick={() => setOpenedNoti(false)}
            >
              <Typography>Close</Typography>
            </Button>
          </div>
        ) : null}
      </div>
    </header>
  );
};
