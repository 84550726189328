import { Images } from "assets";
import { Council, Partners } from "components";
// import { LaunchProjects } from "launchConfig";

import { ProjectIdoProvider } from "../../contexts/ProjectIdoContext";
import { Community, Hero, Launch, Projects, OngoingSales } from "./components";

import styles from "./home-page.module.scss";
import { GettingStarted } from "./components/getting-started/GettingStarted";
import { Empowered } from "./components/empowered/Empowered";
import { ProjectAbstract } from "ProjectAbstract";
import { useEffect, useState } from "react";
import axios from "axios";

export const HomePage = () => {
  useEffect(() => {}, []);
  const [fetchedProjects, setFetchedProjects] = useState<any>([]);
  const [parsedProjects, setParsedProjects] = useState<ProjectAbstract[]>([]); //parsed projects from fetchedProjects

  const fetchAllProjects = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND}/projects`
    );
    console.log(data, "all projects");
    setFetchedProjects(data.data);
    const parsedP = data.data
      .filter((project: any) => project.display_status === "DEFAULT")
      .slice(0, 3)
      .map((project: any) => {
        const parsedProject: ProjectAbstract = {
          id: project._id,
          name: project.name,
          slug: project.slug,
          description: project.description,
          banner: project.image,
          registrationStartDateTime: project.launchDate,
          tiers: Object.keys(project.bucketSize),
          tierValues: Object.values(project.bucketSize),
          tierTiming: project.tierTiming,
          registrationEndDateTime: "",
          tokenPrice: project.price,
          maxAllocation: project.total_count,
          totalRaised: "",
          participants: "0",
          type: "",
          DescriptionComponent: "",
          RoadMapComponent: "",
          TokenomicsComponent: "",
          TeamComponent: "",
          tags: [],
          raiseGoal: "",
          tokenTicker: project.symbol,
          shortDescription: project.description,
          display_status: project.display_status,
        };
        return parsedProject;
      });
    setParsedProjects(parsedP);
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  useEffect(() => {}, [parsedProjects]);

  // const projects = Object.values(LaunchProjects);
  console.log(parsedProjects, "parsed projects");
  const projects = parsedProjects;
  const upcoming = projects.filter((project: ProjectAbstract) =>
    // project.isUpcoming()
    //add the logic here
    {
      const now = new Date();
      if (project.registrationStartDateTime) {
        const startDate = new Date(project.registrationStartDateTime);
        return now < startDate;
      }
      return false;
    }
  );

  return (
    <div className={styles.main}>
      {/* <div className={styles.shadow1}>
        <img src={Images.bgShadow} alt="" />
      </div> */}

      <Hero />
      {/* {Object.keys(LaunchProjects).map((key) => {
        const project = LaunchProjects[key as keyof typeof LaunchProjects];

        if (project.name) {
          return (
            <ProjectIdoProvider project={project}>
              <Launch project={project} />
            </ProjectIdoProvider>
          );
        } else return false;
      })} */}

      <Council bottomShadow={false} />
      <OngoingSales projects={projects} />
      <GettingStarted />
      <Empowered />
      <Partners />
      <Community />
    </div>
  );
};
