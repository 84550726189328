import React, { createContext, ReactNode, useContext, useState } from "react";

import { ModalTabs } from "../constants";
import { Modal } from "pages";

export enum ModalType {
  WALLET_CONNECT = "walletConnect",
  PAYMENT = "payment",
  WALLET_DISCONNECT = "walletDisconnect",
  TRANSACTIONS = "transactions",
  BLOCKED = "blocked",
}

type ModalContextType = {
  [ModalType.WALLET_CONNECT]: boolean;
  [ModalType.PAYMENT]: boolean;
  [ModalType.WALLET_DISCONNECT]: boolean;
  [ModalType.TRANSACTIONS]: boolean;
  [ModalType.BLOCKED]: boolean;
  openModal: (modalName: ModalType, paymentModalTab?: ModalTabs) => void;
  closeModal: (modalName: ModalType) => void;
  paymentModalTab: ModalTabs;
  // transactionsModalTab: ModalTabs;
};

export const ModalContext = createContext<ModalContextType>({
  [ModalType.WALLET_CONNECT]: false,
  [ModalType.PAYMENT]: false,
  [ModalType.WALLET_DISCONNECT]: false,
  [ModalType.TRANSACTIONS]: false,
  [ModalType.BLOCKED]: false,
  openModal: () => {
    console.log("Default openModal invoked");
  },
  closeModal: () => {
    console.log("Default closeModal invoked");
  },
  paymentModalTab: ModalTabs.Bucket,
  // transactionsModalTab: ModalTabs.Bucket,
});

type ModalContextProviderProps = {
  children: ReactNode;
};

export const ModalProvider = ({ children }: ModalContextProviderProps) => {
  const [walletConnectModalOpen, setWalletConnectModalOpen] =
    useState<boolean>(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [paymentModalTab, setPaymentModalTab] = useState<ModalTabs>(
    ModalTabs.Bucket
  );

  // const [transactionsModalTab, setTransactionsModalTab] =
  //   useState<ModalTabs>(ModalTabs.Transactions);

  const [walletDisconnectModalOpen, setWalletDisconnectModalOpen] =
    useState<boolean>(false);

  const [transactionsModalOpen, setTransactionsModalOpen] =
    useState<boolean>(false);

  const [blockedModalOpen, setBlockedModalOpen] = useState<boolean>(false);

  const openModal = (modalName: ModalType, modalTab?: ModalTabs) => {
    console.log("openModal", modalName);
    if (modalName === ModalType.WALLET_CONNECT) {
      setWalletConnectModalOpen(true);
    } else if (modalName === ModalType.PAYMENT) {
      setPaymentModalOpen(true);
      if (modalTab) setPaymentModalTab(modalTab);
    } else if (modalName === ModalType.WALLET_DISCONNECT) {
      setWalletDisconnectModalOpen(true);
    } else if (modalName === ModalType.TRANSACTIONS) {
      setTransactionsModalOpen(true);
    } else if (modalName === ModalType.BLOCKED) {
      setBlockedModalOpen(true);
    }
  };

  const closeModal = (modalName: ModalType) => {
    // console.log("closeModal", modalName);
    if (modalName === ModalType.WALLET_CONNECT) {
      setWalletConnectModalOpen(false);
    } else if (modalName === ModalType.PAYMENT) {
      setPaymentModalOpen(false);
    } else if (modalName === ModalType.WALLET_DISCONNECT) {
      setWalletDisconnectModalOpen(false);
    } else if (modalName === ModalType.TRANSACTIONS) {
      setTransactionsModalOpen(false);
      // setTransactionsModalTab(ModalTabs.Transactions);
    } else if (modalName === ModalType.BLOCKED) {
      setBlockedModalOpen(false);
    }
  };

  return (
    <ModalContext.Provider
      value={{
        [ModalType.WALLET_CONNECT]: walletConnectModalOpen,
        [ModalType.PAYMENT]: paymentModalOpen,
        [ModalType.WALLET_DISCONNECT]: walletDisconnectModalOpen,
        [ModalType.TRANSACTIONS]: transactionsModalOpen,
        [ModalType.BLOCKED]: blockedModalOpen,
        // transactionsModalTab,
        paymentModalTab,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal: any = () => useContext(ModalContext);
