import { Images } from "assets";
import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import Loader from "react-js-loader";
import { ProjectIdoProvider } from "../../contexts/ProjectIdoContext";
// import { LaunchProjects, TProjectName } from "../../launchConfig";
import { Modal } from "../Modal";
import { Banner, RightTab, TabSelect, TopInfo, TopRoute } from "./components";

import styles from "./Project.module.scss";
import { TransactionModal } from "pages/TransactionsModal";
import { ModalType, useModal } from "contexts";
import { ProjectAbstract } from "ProjectAbstract";
import axios from "axios";
import Markdown from "react-markdown";
import { AboutProject } from "./components/aboutProject";
import { Roadmap } from "./components/roadmap";
import { Tokenomics } from "./components/tokenomics";
import { Team } from "./components/team";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Project = () => {
  const { projectSlug }: { projectSlug: any } = useParams();
  const [openedTab, setTab] = useState<string>("About Project");
  const [project, setProject] = useState<ProjectAbstract | null>(null);
  const [loading, setLoading] = useState(true);

  const [mounted, setMounted] = useState(false);

  // useEffect(() => {
  //   setMounted(true);
  // }, []);

  const {
    [ModalType.TRANSACTIONS]: transactionsModalOpen,
    // transactionsModalTab,
    closeModal,
    openModal,
  } = useModal();

  useEffect(() => {
    if (projectSlug) {
      fetchProjectDetails();
    }
  }, []);

  async function fetchProjectDetails() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND}/projects/`,
      {
        params: {
          slug: projectSlug,
        },
      }
    );
    console.log(data.data[0], "project data");
    const firstProject = data.data[0];
    const parsedP: ProjectAbstract = {
      id: firstProject._id,
      name: firstProject.name,
      slug: firstProject.slug,
      description: firstProject.description,
      banner: firstProject.banner_url,
      logo: firstProject.logo_url,
      registrationStartDateTime: firstProject.launchDate,
      registrationEndDateTime: "",
      tokenPrice: firstProject.price.toString(),
      maxAllocation: firstProject.total_count,
      totalRaised: firstProject.total_amount.toString(),
      participants: firstProject.total_users,
      type: "",
      DescriptionComponent: firstProject.about,
      RoadMapComponent: firstProject.roadmap,
      TokenomicsComponent: firstProject.tokenomics,
      TeamComponent: firstProject.team,
      tags: [],
      raiseGoal: (firstProject.total_count * firstProject.price).toString(),
      tokenTicker: firstProject.symbol,
      shortDescription: firstProject.description,
      display_status: firstProject.display_status,
      tiers: Object.keys(firstProject.bucketSize),
      tierValues: firstProject.bucketSize,
      tierTiming: firstProject.tierTiming,
    };
    console.log(parsedP, "parsed project");
    setProject(parsedP);
    setMounted(true);
    setLoading(false);
  }

  useEffect(() => {
    console.log(project, "project obj");
  }, [project]);

  // if (!project) {
  //   return <Redirect to={"/launchpad"} />;
  // }
  const dummyMarkdown = " # Hi, *Pluto*! ";

  return (
    <>
      {loading ? (
        <>
          <Skeleton
            count={1}
            baseColor="#252525"
            highlightColor="#11111"
            enableAnimation={true}
            width={"60%"}
            height={"150px"}
            borderRadius={10}
            style={{
              marginTop: "25px",
              marginBottom: "10px",
              position: "relative",
              left: "10%",
              top: "20%",
            }}
          />
          <Skeleton
            count={2}
            baseColor="#252525"
            highlightColor="#11111"
            width={"60%"}
            height={"20%"}
            borderRadius={10}
            style={{
              marginBottom: "10px",
              position: "relative",
              left: "10%",
              top: "20&",
            }}
          />
        </>
      ) : project ? (
        <ProjectIdoProvider project={project}>
          <div className={styles.main}>
            <div className={styles.shadow1}>
              <img src={Images.bgShadow} alt="" />
            </div>
            <div className={styles.inside}>
              <TopRoute project={project} />
              <TopInfo project={project} />
              <div className={styles.bottom}>
                <div className={styles.left}>
                  <Banner src={project.banner} />

                  <div className={styles.rightMobile}>
                    <RightTab project={project} />
                  </div>
                  <TabSelect openedTab={openedTab} setTab={setTab} />
                  {openedTab === "About Project" ? (
                    <AboutProject data={project.DescriptionComponent} />
                  ) : (
                    <></>
                  )}
                  {openedTab === "Road Map" ? (
                    <Roadmap data={project.RoadMapComponent} />
                  ) : (
                    <></>
                  )}
                  {openedTab === "Tokenomics" ? (
                    <Tokenomics data={project.TokenomicsComponent} />
                  ) : (
                    <></>
                  )}
                  {openedTab === "Team" ? <Team /> : <></>}
                </div>
                <div className={styles.right}>
                  <RightTab project={project} />
                </div>
              </div>
            </div>
          </div>
          <Modal />
          {transactionsModalOpen && <TransactionModal />}

          {/* <TransactionModal /> */}
        </ProjectIdoProvider>
      ) : null}
    </>
  );
};
