import dotenv from "dotenv";
import axios from "axios";
import { useSignMessage } from "hooks";
import { createContext, useContext, useEffect, useState } from "react";
import { API_ENDPOINTS } from "services/apiEndpoints";
import { ClaimRequest, ConfirmBucketRequest } from "services/types";
import {
  claimStatusService,
  claimTokensService,
  confirmBucketService,
} from "services/userService";

import { Icons } from "../assets";
import { getAddressLastTXS } from "../btcConfig";
import { ApiMessages, BucketTypes, Messages } from "../constants";
import { useWallet } from "./WalletContext";
import { set } from "date-fns";
import { config } from "process";

export const bucketSizeToBucketTypesMap: Record<string, BucketTypes> = {
  SMALL: BucketTypes.Bronze,
  MEDIUM: BucketTypes.Silver,
  LARGE: BucketTypes.Gold,
};

export enum UserFundingStatus {
  NOT_STARTED = "NOT_STARTED",
  BUCKET_CHOSEN = "BUCKET_CHOSEN",
  PAID = "PAID",
  PAID_FROM_ORDINALS = "PAID_FROM_ORDINALS",
  SUCCESS = "SUCCESS",
  PAID_TOO_LESS = "PAID_TOO_LESS",
  FAILURE_NO_PAYMENT = "EXPIRED",
  FAILURE_TOO_LATE = "EXPIRED",
  FAILURE_PAID_TOO_LESS = "EXPIRED",
  READY_TO_CLAIM = "READY_TO_CLAIM",
  CLAIMED = "CLAIMED",
}

export const UserFundingStatusText: Record<UserFundingStatus, string> = {
  NOT_STARTED: "You did not take part in this IDO.",
  BUCKET_CHOSEN:
    "We are awaiting your payment. If you completed your payment, do not worry, it can take several BTC blocks to confirm it on our side.",
  PAID: "Payment confirmed! Your tokens will be ready to claim after IDO finish.",
  PAID_FROM_ORDINALS:
    "Payment confirmed! Your tokens will be ready to claim after IDO finish",
  SUCCESS: "Congratulation, you will be able to claim your allocation shortly!",
  PAID_TOO_LESS:
    "We did not receive enough fund to qualify You for this IDO. Please fulfill your payment.",
  EXPIRED: "You failed to send us funds for this IDO.",
  READY_TO_CLAIM:
    "Congratulation! Your tokens and any overpayment is ready to be claimed now.",
  CLAIMED: "Congratulation! You claimed your tokens.",
};

export const UserFundingStatusIcon: Record<UserFundingStatus, any> = {
  NOT_STARTED: "",
  BUCKET_CHOSEN: Icons.info,
  PAID: Icons.greenCheck,
  PAID_FROM_ORDINALS: Icons.greenCheck,
  SUCCESS: Icons.success,
  READY_TO_CLAIM: Icons.greenCheck,
  CLAIMED: Icons.success,
  PAID_TOO_LESS: Icons.sadFace,
  EXPIRED: Icons.sadFace,
};

export interface FundingStatus {
  deposit: string;
  overDeposit: string;
  // approvedDeposit: string;
  // txs: string[];
  bucketSize: BucketTypes;
  status: UserFundingStatus;
  txn_hash?: string;
  createdAt: string;
}

export interface ClaimStatus {
  brcTxId: string;
  btcTxId: string;
}

export interface ProjectContextType {
  participants: string;
  totalRaised: string;
  raiseGoal: string;
  [BucketTypes.Bronze]: string;
  [BucketTypes.Silver]: string;
  [BucketTypes.Gold]: string;
  BronzeReq: string;
  SilverReq: string;
  GoldReq: string;
  BTCPrice: string;
  tokenHoldings: string;
  tokenPrice: string;
  fee: string;
  ticker: string;
  confirmBucket: (bucketType: BucketTypes) => void;
  claimTokens: () => void;
  bucketConfirmed: boolean;
  fundingStati: FundingStatus[];
  fundingStatus: FundingStatus | null;
  claimStatus: ClaimStatus | null;
  slug: string;
  id: string;
  price: string;
  isTransactions: boolean;
  maxMint: string;
  launchDate: string;
  bannerUrl: string;
  logo: string;
  tier: string;
  allocation: string;
  notWhitelisted: boolean;
  checkFundingStatus: () => void;
  totalMinted: string;
  tiers: string[];
  tierTiming: string[];
  tierValues: number[];
}

export const ProjectIdoContext = createContext<ProjectContextType>({
  participants: "0",
  totalRaised: "0",
  raiseGoal: "0",
  [BucketTypes.Bronze]: "0",
  [BucketTypes.Silver]: "0",
  [BucketTypes.Gold]: "0",
  BronzeReq: "0",
  SilverReq: "0",
  GoldReq: "0",
  BTCPrice: "0",
  tokenHoldings: "0",
  tokenPrice: "0",
  fee: "0",
  ticker: "",
  confirmBucket: (bucketType: BucketTypes) => {
    console.log("Default confirmBucker invoked ", bucketType);
  },
  claimTokens: () => {
    console.log("Default claimTokens invoked ");
  },
  bucketConfirmed: false,
  fundingStati: [],
  fundingStatus: null,
  claimStatus: null,
  slug: "",
  id: "",
  price: "",
  isTransactions: false,
  maxMint: "0",
  launchDate: "",
  bannerUrl: "",
  logo: "",
  tier: "",
  notWhitelisted: false,
  //need to check return type
  checkFundingStatus: () => void {},
  allocation: "0",
  totalMinted: "0",
  tiers: [],
  tierTiming: [],
  tierValues: [],
});

export const ProjectIdoProvider = ({ children, project }: any) => {
  const [participants, setParticipants] = useState(project.participants);
  const [totalRaised, setTotalRaised] = useState(project.totalRaised);
  const [raiseGoal, setRaiseGoal] = useState(project.raiseGoal);
  const [tokenPrice, setTokenPrice] = useState(project.tokenPrice);
  const [launchDate, setLaunchDate] = useState(
    project.registrationStartDateTime
  );
  const [bannerUrl, setBannerUrl] = useState(project.image);
  const [logo, setLogo] = useState(project.logo);
  const [slug, setSlug] = useState(project.slug);
  const [id, setId] = useState(project.id);
  const [price, setPrice] = useState(project.tokenPrice);
  const [fee, setFee] = useState("1000");
  const [maxMint, setMaxMint] = useState("1000");
  const [ticker] = useState(project.tokenTicker);
  const [Bronze, setBronze] = useState("0");
  const [Mercury, setMercury] = useState("0");
  const [Silver, setSilver] = useState("0");
  const [Gold, setGold] = useState("0");
  const [BronzeReq, setBronzeReq] = useState("0");
  const [SilverReq, setSilverReq] = useState("3");
  const [GoldReq, setGoldReq] = useState("15");
  const [BTCPrice, setBTCPrice] = useState("30630");
  const [tokenHoldings, setTokenHoldings] = useState("0");
  const [bucketConfirmed, setBucketConfirmed] = useState(true);
  const [tier, setTier] = useState("MERCURY");
  const [allocation, setAllocation] = useState("0");
  const [totalMinted, setTotalMinted] = useState("0");
  const [tiers, setTiers] = useState(project.tiers);
  const [tierValues, setTierValues] = useState(project.tierValues);
  const [tierTiming, setTierTiming] = useState(project.tierTiming);
  const [fundingStati, setFundingStati] = useState<FundingStatus[]>([
    {
      deposit: "0",
      overDeposit: "0",
      bucketSize: BucketTypes.Bronze,
      status: UserFundingStatus.NOT_STARTED,
    },
  ] as FundingStatus[]);
  const [fundingStatus, setFundingStatus] = useState<FundingStatus | null>({
    deposit: "0",
    overDeposit: "0",
    bucketSize: BucketTypes.Bronze,
    status: UserFundingStatus.NOT_STARTED,
  } as FundingStatus);
  const [claimStatus, setClaimStatus] = useState<ClaimStatus | null>(null);

  const [isTransactions, setIsTransactions] = useState<boolean>(false);

  const [notWhitelisted, setNotWhitelisted] = useState<boolean>(true);

  const { signMessage } = useSignMessage();

  const { walletData, registeredUserData, lastTx, setLastTx } = useWallet();

  const selectBucketMessage = (
    bucketType: BucketTypes
  ): { wallet: string; api: string } => {
    switch (bucketType) {
      case BucketTypes.Bronze:
        return {
          wallet: Messages.SELECT_SMALL_BUCKET,
          api: ApiMessages.SELECT_SMALL_BUCKET,
        };
      case BucketTypes.Silver:
        return {
          wallet: Messages.SELECT_MEDIUM_BUCKET,
          api: ApiMessages.SELECT_MEDIUM_BUCKET,
        };
      case BucketTypes.Gold:
        return {
          wallet: Messages.SELECT_LARGE_BUCKET,
          api: ApiMessages.SELECT_LARGE_BUCKET,
        };
      default:
        throw new Error(`Invalid BucketType: ${bucketType}`);
    }
  };

  const confirmBucket = async (bucketType: BucketTypes) => {
    const bucketMsg = selectBucketMessage(bucketType);
    const signature = await signMessage(bucketMsg.wallet);
    if (signature && walletData) {
      const requestBody: ConfirmBucketRequest = {
        public: walletData.ordinalsPublicKey || "",
        msg: bucketMsg.api,
        sign: signature,
      };
      const result = await confirmBucketService(requestBody, project.id);
      if (result) {
        const serverBucketSize = result.bucketSize;
        const fStatus = {
          ...result,
          bucketSize:
            bucketSizeToBucketTypesMap[
              serverBucketSize as keyof typeof bucketSizeToBucketTypesMap
            ],
        };
        setBucketConfirmed(true);
        // setFundingStatus(fStatus);
      }
    }
  };

  const claimTokens = async () => {
    const msg = {
      wallet: Messages.CLAIM,
      api: ApiMessages.CLAIM,
    };
    const signature = await signMessage(msg.wallet);
    if (signature && walletData) {
      const requestBody: ClaimRequest = {
        public: walletData.ordinalsPublicKey || "",
        msg: msg.api,
        sign: signature,
      };
      const result = await claimTokensService(requestBody, project.id);
      if (result) {
        setClaimStatus(result);
      }
    }
  };

  const checkFundingStatus = async () => {
    console.log("checkFundingStatus invoked");
    if (walletData) {
      console.log("whitelist res");
      const whiteListRes = await axios.post(
        `${process.env.REACT_APP_BACKEND}/transactions/whitelistCheck`,
        {
          user_wallet_address: walletData.ordinalsAddress,
          project_id: id,
        }
      );
      console.log(whiteListRes, "whiteListRes");
      if (!whiteListRes.data.data) {
        console.log("not whitelisted");
        setNotWhitelisted(true);
      } else {
        setNotWhitelisted(false);
      }

      try {
        const data = await axios.get(
          `${process.env.REACT_APP_BACKEND}/transactions`,
          {
            params: {
              "user.wallet_address": walletData.ordinalsAddress,
              "project.slug": slug,
            },
          }
        );

        if (data.data.data.length > 0) {
          console.log(data.data.data, data.data.data.length);
          setIsTransactions(true);
          let fundingStatiObj: FundingStatus[] = [];
          data.data.data.forEach((item: any) => {
            fundingStatiObj.push({
              deposit: item.amount,
              overDeposit: item.overDeposit,
              bucketSize: item.bucketSize,
              status: item.status,
              txn_hash: item.txn_hash ? item.txn_hash : undefined,
              createdAt: item.updatedAt,
            });
          });
          console.log(fundingStatiObj, "fundingStatiObj");
          setFundingStati(fundingStatiObj as FundingStatus[]);
        } else {
          setIsTransactions(false);
        }
      } catch (e) {
        console.error(e, "checkFundingStatus error");
      }
    } else {
      setFundingStati([]);
      setBucketConfirmed(false);
    }
  };

  useEffect(() => {
    const checkTier = async () => {
      if (walletData) {
        //check tier
        const tierRes = await axios.get(
          `${process.env.REACT_APP_BACKEND}/users`,
          {
            params: {
              wallet_address: walletData.ordinalsAddress,
              project_id: id,
            },
          }
        );
        if (tierRes.data.data.length === 0) {
          return;
        }
        const maxMintRes = await axios.get(
          `${process.env.REACT_APP_BACKEND}/projects`,
          {
            params: {
              slug,
            },
          }
        );
        console.log(
          maxMintRes.data.data[0].bucketSize[
            tierRes.data.data[0].bucketSize.toLowerCase()
          ],
          "maxMintRes"
        );
        console.log(tierRes.data.data[0].bucketSize, "tierRes");
        console.log(tierRes.data.data[0].numberCanMint, "allocation");
        console.log(tierRes.data.total_minted, "total minted");
        setAllocation(tierRes.data.data[0].numberCanMint);
        setTotalMinted(
          (
            parseInt(tierRes.data.data[0].numberCanMint) -
            parseInt(tierRes.data.total_minted)
          ).toString()
        );
        setTier(tierRes.data.data[0].bucketSize);
        setMaxMint(
          Math.min(
            maxMintRes.data.data[0].bucketSize[
              tierRes.data.data[0].bucketSize.toLowerCase()
            ],
            tierRes.data.data[0].numberCanMint,
            parseInt(tierRes.data.data[0].numberCanMint) -
              parseInt(tierRes.data.total_minted)
          ).toString()
        );
      }
    };
    checkFundingStatus();
    checkTier();
  }, [walletData, registeredUserData]);

  useEffect(() => {
    const getIdoData = async () => {
      const getProjectData = async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND}/projects`,
          {
            params: {
              slug,
            },
          }
        );
        return data;
      };

      const projectData = await getProjectData();
      //set the project context here
      console.log(projectData, "projectData");
    };

    const idoRefreshInterval = setInterval(getIdoData, 120000);
    // getIdoData();
    return () => {
      clearInterval(idoRefreshInterval);
    };
  }, [walletData]);

  useEffect(() => {
    const getTxs = async () => {
      const { data } = await axios.get(
        getAddressLastTXS(registeredUserData!.depositAddress)
      );
      if (data[0]) {
        setLastTx(data[0].txid);
      }
    };

    if (registeredUserData?.depositAddress) {
      getTxs();
    }
  }, [lastTx, registeredUserData, walletData, setLastTx]);

  // useEffect(() => {
  //   const getClaimStatus = async () => {
  //     if (
  //       fundingStatus?.status &&
  //       fundingStatus?.status === UserFundingStatus.CLAIMED &&
  //       walletData?.ordinalsAddress
  //     ) {
  //       const res = await claimStatusService(
  //         walletData.ordinalsAddress!,
  //         project.id
  //       );
  //       if (res.withdrawBrcTx) {
  //         setClaimStatus({
  //           brcTxId: res.withdrawBrcTx,
  //           btcTxId: res.withdrawBtcTx,
  //         });
  //       }
  //     }
  //   };
  //   getClaimStatus();
  // }, [walletData, fundingStatus, project.id]);

  return (
    <ProjectIdoContext.Provider
      value={{
        tiers,
        tierValues,
        checkFundingStatus,
        participants,
        totalRaised,
        raiseGoal,
        Mercury,
        Silver,
        Gold,
        BronzeReq,
        SilverReq,
        GoldReq,
        BTCPrice,
        tokenHoldings,
        tokenPrice,
        fee,
        ticker,
        confirmBucket,
        fundingStati,
        fundingStatus,
        bucketConfirmed,
        claimTokens,
        claimStatus,
        slug,
        id,
        price,
        isTransactions,
        maxMint,
        launchDate,
        bannerUrl,
        logo,
        tier,
        tierTiming,
        notWhitelisted,
        allocation,
        totalMinted,
      }}
    >
      {children}
    </ProjectIdoContext.Provider>
  );
};

export const useProjectIdo = () => useContext(ProjectIdoContext);
