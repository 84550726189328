import arrowDown from "./arrowDown.svg";
import arrowForward from "./arrowForward.svg";
import arrowRight from "./arrowRight.svg";
import bell from "./bell.svg";
import burger from "./burger.svg";
import check from "./check.svg";
import copy from "./copy.svg";
import discord from "./discord.svg";
import greenCheck from "./green_check.svg";
import info from "./info.svg";
import naviWallet from "./naviWallet.svg";
import noti_question from "./noti_question.svg";
import noti_success from "./noti_success.svg";
import noti_warning from "./noti_warning.svg";
import quote from "./quote.svg";
import sadFace from "./sad_face.svg";
import success from "./success.svg";
import telegram from "./telegram.svg";
import twitter from "./twitter.svg";
import wallet_connected from "./wallet_connected.svg";
import wallet_external from "./wallet_external.svg";
import wallet_hiro from "./leather.png";
import wallet_qrcode from "./wallet_qrcode.svg";
import wallet_unisat from "./wallet_unisat.svg";
import wallet_xverse from "./verse.jpeg";
import wallet_okx from "./okx.png";
import cncl from "./cncl_icon.png";
import tshx from "./tshx_icon.png";

export const Icons = {
  arrowDown,
  arrowForward,
  bell,
  burger,
  naviWallet,
  quote,
  arrowRight,
  copy,
  check,
  greenCheck,
  info,
  sadFace,
  success,
  noti_question,
  noti_success,
  noti_warning,
  Tokens: {
    cncl,
    tshx,
  },
  Socials: {
    discord,
    telegram,
    twitter,
  },
  Wallets: {
    wallet_connected,
    wallet_external,
    wallet_qrcode,
    wallet_xverse,
    wallet_unisat,
    wallet_hiro,
    wallet_okx,
  },
};
