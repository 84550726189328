import { network } from "btcConfig";
import { WalletLinks } from "constants/index";
import { useWallet } from "contexts";
import { useCallback } from "react";
import { WalletName } from "types";

declare global {
  interface Window {
    okxwallet: any;
  }
}

export const useOkxConnection = () => {
  const { connectWallet } = useWallet();

  const connectOkxWallet = useCallback(async () => {
    let okx: any;
    if (typeof window.okxwallet !== "undefined") {
      console.log("OKX is installed!");
      okx = await window.okxwallet.bitcoin.connect();
    }
    if (okx) {
      console.log("okx", okx);
      const accounts = await window.okxwallet.bitcoin.requestAccounts();
      if (accounts.length > 0) {
        const address = accounts[0];
        const publicKey = await window.okxwallet.bitcoin.getPublicKey();

        console.log(address, publicKey, "address and public key");

        connectWallet({
          paymentAddress: address,
          paymentPublicKey: publicKey,
          ordinalsAddress: address,
          ordinalsPublicKey: publicKey,
          walletName: WalletName.Okx,
        });
      }
    } else {
      console.log("Okx is not installed.");
    }
  }, [connectWallet]);
  return { connectOkxWallet };
};
