import { Button, Typography } from "@mui/material";
import { Icons, Images } from "assets";

import styles from "./Hero.module.scss";

export const Hero = () => (
  <div className={styles.hero}>
    <img src={Images.heroPlanet} className={styles.planet} alt="" />
    <h1 className={styles.h1}>Pioneering the Bitcoin frontier</h1>
    <h3 className={styles.h3}>
      Unlock the potential of Ordinals protocol. Whether you're a visionary
      startup or an established enterprise.
    </h3>
    {/* <Scene /> */}
    <div className={styles.bottom}>
      <Button
        className={styles.leftBtn}
        onClick={() => {
          window.open("https://realm.wispform.com/4fc81793", "_blank");
        }}
      >
        <Typography>Launch your Project</Typography>
      </Button>
    </div>
    <div className={styles.rightBtns}>
      <Button
        className={styles.rightBtn}
        href="https://discord.com/invite/ordinalscouncil"
        target="_blank"
      >
        <img src={Icons.Socials.discord} alt="Discord" />
      </Button>
      <Button
        className={styles.rightBtn}
        href="https://t.me/+9eED1XpNuuNiOGM1"
        target="_blank"
      >
        <img src={Icons.Socials.telegram} alt="Telegram" />
      </Button>
      <Button
        className={styles.rightBtn}
        href="https://twitter.com/toshipadcom"
        target="_blank"
      >
        <img src={Icons.Socials.twitter} alt="Twitter" />
      </Button>
    </div>
  </div>
);
