import styles from "./empowered.module.scss";

export const Empowered = () => {
  return (
    <div className={styles.main}>
      <div className={styles.text}>
        Empowering Innovation, Project by Project.
        <br /> Fostering Creators, Safeguarding Value.
      </div>
      <button
        className={styles.applyButton}
        onClick={() => {
          window.open("https://realm.wispform.com/4fc81793", "_blank");
        }}
      >
        Launch your Project
      </button>
    </div>
  );
};
