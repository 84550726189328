import { Alert, Button, Slider, Typography } from "@mui/material";
import { Icons } from "assets";
import { ModalTabs, PaymentMethod } from "constants/index";
import { useEffect, useState } from "react";
import styles from "./Payment.module.scss";
import { useWallet } from "contexts";
import { useProjectIdo } from "contexts/ProjectIdoContext";
import axios from "axios";
import { set } from "date-fns";

export const Payment = (props: { setPaymentMethod: any; setTab: any }) => {
  const [customFees, setCustomFees] = useState<number>(50);
  const {
    setNetworkFee,
    networkFee,
    walletData,
    setTransactionId,
    setTokensBought,
    setAdditionalFee,
    setChainFee,
    setFee,
    setOrderTotal,
    tokensBought,
  } = useWallet();

  const { id } = useProjectIdo();
  const [swr, setSwr] = useState<boolean>(false);

  const [displayError, setDisplayError] = useState<string>("");

  const [mempoolMinFee, setMempoolMinFee] = useState<number>(0);
  const [mempoolFastestFee, setMempoolFastestFee] = useState<number>(0);

  const handleFetchMinimumMempoolFee = async () => {
    const mempool_api_get_recommended_fee_url =
      "https://mempool.space/api/v1/fees/recommended";

    try {
      const res = await axios.get(mempool_api_get_recommended_fee_url);
      console.log(res.data, "mempool fee");
      setMempoolMinFee(res.data.halfHourFee + 3);
      setMempoolFastestFee(res.data.fastestFee + 3);
    } catch (err: any) {
      console.error(err, "mempool fee failed");
    }
  };

  useEffect(() => {
    handleFetchMinimumMempoolFee();
  }, []);

  const handleSelectFeeType = (type: string) => {
    if (type === "economy") {
      document.getElementById("custom")?.classList.remove(styles.selected);
      document.getElementById("normal")?.classList.remove(styles.selected);
      document.getElementById("economy")?.classList.add(styles.selected);
      setCustomFees(mempoolMinFee);
    } else if (type === "normal") {
      document.getElementById("economy")?.classList.remove(styles.selected);
      document.getElementById("custom")?.classList.remove(styles.selected);
      document.getElementById("normal")?.classList.add(styles.selected);
      setCustomFees(mempoolFastestFee);
    } else {
      document.getElementById("economy")?.classList.remove(styles.selected);
      document.getElementById("normal")?.classList.remove(styles.selected);
      document.getElementById("custom")?.classList.add(styles.selected);
    }
  };

  const handleCreateOrder = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND}/transactions`,
        {
          user_wallet_address: walletData?.ordinalsAddress,
          amount: tokensBought.toString(), //no of tokens to claim
          project_id: id,
          networkFee: customFees,
        }
      );
      if (res.status === 201) {
        console.log(res.data.data, "order created OC");
        console.log(res.data.data.transaction, "transaction obj");
        console.log(res.data.data.charge, "charge obj");
        setTransactionId(res.data.data.transaction._id);
        setAdditionalFee(
          res.data.data.charge.additionalFee
            ? res.data.data.charge.additionalFee
            : 0
        );
        setChainFee(res.data.data.charge.chainFee);
        setFee(res.data.data.charge.mempool_fee);
        //setOrderTotal(res.data.data.charge.total);
        setOrderTotal(res.data.data.charge.amount);
        console.log(res.data.data.transaction.createdAt, "transaction id");
        window.localStorage.setItem(
          "createdAt",
          res.data.data.transaction.createdAt
        );
        console.log(JSON.parse(res.data.data.transaction.charge), "charge obj");
        window.localStorage.setItem(
          "toshipad-invoice-info",
          res.data.data.transaction.charge
        );
        window.localStorage.setItem(
          "invoice_id",
          res.data.data.transaction.invoice_id
        );
        setNetworkFee(customFees);
        props.setPaymentMethod(PaymentMethod.ConnectedWallet);
        props.setTab(ModalTabs.TransactionSummary);
      } else {
        setSwr(true);
        console.log(res.data.message, "order failed");
        if (res.data.status === 429) {
          setDisplayError(
            "Too many requests, please try again after 10-15 seconds"
          );
        } else {
          setDisplayError(res.data.message);
        }
      }
    } catch (err: any) {
      setSwr(true);
      console.error(err, "create order failed");
      setDisplayError(err.data.message);
    }
  };

  return (
    <div className={styles.section}>
      {swr && (
        <Alert
          severity="error"
          sx={{
            display: swr ? "flex" : "none",
            marginBottom: "20px",
          }}
        >
          {displayError}
        </Alert>
      )}
      <Typography className={styles.title}>
        Select the network fee you want to pay
      </Typography>
      <div className={styles.points}>
        <div className={styles.threeOptions}>
          <div
            className={styles.oneOption}
            id="economy"
            onClick={() => handleSelectFeeType("economy")}
          >
            <Typography className={styles.leftTitle}>Normal</Typography>
            <Typography className={styles.leftDesc}>
              {mempoolMinFee} sats/vB
            </Typography>
          </div>
          <div
            className={styles.oneOption}
            id="normal"
            onClick={() => handleSelectFeeType("normal")}
          >
            <Typography className={styles.leftTitle}>Fast</Typography>
            <Typography className={styles.leftDesc}>
              {mempoolFastestFee} sats/vB
            </Typography>
          </div>
          <div
            className={styles.oneOption}
            id="custom"
            onClick={() => handleSelectFeeType("custom")}
          >
            <Typography className={styles.leftTitle}>Custom</Typography>
            <Typography className={styles.leftDesc}>
              {customFees} sats/vB
            </Typography>
          </div>
        </div>
        <Typography className={styles.customTitle}>
          Select Custom Network Fee
        </Typography>
        <Slider
          size="small"
          defaultValue={50}
          value={customFees}
          aria-label="Small"
          valueLabelDisplay="auto"
          min={mempoolMinFee}
          max={mempoolFastestFee + 100}
          sx={{
            color: "#ff9900",
            width: "100%",
            alignSelf: "center",
            marginBottom: "20px",
          }}
          onChange={(event: any, newValue: number) => {
            if (!event) return;
            setCustomFees(newValue);
          }}
        />

        {/* wallet code */}
        {/* <div
        className={styles.onePoint}
        onClick={() => {
          props.setPaymentMethod(PaymentMethod.ConnectedWallet)
          props.setTab(ModalTabs.TransactionSummary)
        }}>
        <div className={styles.left}>
          <div className={styles.icon}>
            <img src={Icons.Wallets.wallet_connected} alt="" />
          </div>
          <div className={styles.names}>
            <Typography className={`${styles.leftTitle} ${styles.noDesc}`}>
              Connected Wallet
            </Typography>
          </div>
        </div>
        <div className={styles.right}>
          <img src={Icons.arrowForward} alt="" />
        </div>
      </div> */}
        {/* wallet code ends */}
        {/* <div
        className={styles.onePoint}
        onClick={() => {
          props.setPaymentMethod(PaymentMethod.ExternalWallet)
          props.setTab(ModalTabs.TransactionSummary)
        }}>
        <div className={styles.left}>
          <div className={styles.icon}>
            <img src={Icons.Wallets.wallet_external} alt="" />
          </div>
          <div className={styles.names}>
            <Typography className={styles.leftTitle}>
              External Wallet
            </Typography>
            <Typography className={styles.leftDesc}>
              Exchange Wallets etc.
            </Typography>
          </div>
        </div>
        <div className={styles.right}>
          <img src={Icons.arrowForward} alt="" />
        </div>
      </div> */}
        {/*<div*/}
        {/*  className={styles.onePoint}*/}
        {/*  onClick={() => {*/}
        {/*    props.setPaymentMethod(PaymentMethod.QRCode)*/}
        {/*    props.setTab(ModalTabs.TransactionSummary)*/}
        {/*  }}>*/}
        {/*  <div className={styles.left}>*/}
        {/*    <div className={styles.icon}>*/}
        {/*      <img src={Icons.Wallets.wallet_qrcode} alt="" />*/}
        {/*    </div>*/}
        {/*    <div className={styles.names}>*/}
        {/*      <Typography className={`${styles.leftTitle} ${styles.noDesc}`}>*/}
        {/*        QR Code*/}
        {/*      </Typography>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={styles.right}>*/}
        {/*    <img src={Icons.arrowForward} alt="" />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className={styles.bottomButtons}>
        <Button
          className={`${styles.btn}`}
          onClick={() => {
            props.setTab(ModalTabs.Bucket);
          }}
        >
          <Typography>Back</Typography>
        </Button>
        <Button
          disabled={customFees === 0}
          className={`${styles.btn} ${styles.rightBtn}`}
          onClick={() => {
            handleCreateOrder();
          }}
        >
          <Typography>Proceed</Typography>
        </Button>
      </div>
    </div>
  );
};
