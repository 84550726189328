import binanceNft from "./binanceNft.svg";
import btcCulture from "./btcCulture.svg";
import centauri from "./centauri.svg";
import fomocraft from "./fomocraft.svg";
import gdaCapital from "./gdaCapital.svg";
import jpegCulture from "./jpegCulture.svg";
import lucidBlue from "./lucidBlue.svg";
import magicEden from "./magicEden.svg";
import niftables from "./niftables.svg";
import okex from "./okex.svg";
import ordinalsWallet from "./ordinalsWallet.svg";
import sheeshaFinance from "./sheeshaFinance.svg";
import skyVision from "./skyVision.svg";
import xverseWallet from "./xverseWallet.svg";
import zogiLabs from "./zogiLabs.svg";
import newTribe from "./newTribe.svg";

export const PartnerLogos = {
  binanceNft,
  btcCulture,
  centauri,
  fomocraft,
  gdaCapital,
  jpegCulture,
  lucidBlue,
  magicEden,
  niftables,
  okex,
  ordinalsWallet,
  sheeshaFinance,
  skyVision,
  xverseWallet,
  zogiLabs,
  newTribe,
};
