import aboutUs from "./aboutUs.png";
import bgShadow from "./bgShadow.png";
import council1 from "./council1.png";
import councilShadow from "./councilShadow.png";
import dotsMobile from "./dots-mobile.png";
import dots from "./dots.png";
import heroPlanet from "./heroPlanet.png";
import quoteBg from "./quoteBg.png";
import toshipadLogo from "./toshipadLogo.svg";
import comingSoon from "./comingSoon.png";

export const Images = {
  heroPlanet,
  council1,
  councilShadow,
  bgShadow,
  toshipadLogo,
  aboutUs,
  dots,
  dotsMobile,
  quoteBg,
  comingSoon,
};
