import { Typography } from "@mui/material";
import React from "react";
import styles from "./Roadmap.module.scss";
import { useProjectIdo } from "contexts/ProjectIdoContext";
import Markdown from "react-markdown";
interface RoadmapProps {
  data: string;
}
export const Roadmap = ({ data }: RoadmapProps) => {
  const { slug } = useProjectIdo();
  console.log(slug);

  return (
    <>
      <div className={styles.roadmap}>
        <Typography className={styles.mainTitle}>
          Roadmap: Future of the Project
        </Typography>
        <Markdown>{data}</Markdown>
        {/* {slug === "BRC20Dex" && (
          <>
            
            <Typography className={styles.desc}>
              Due to The Council Ecosystem’s spiral value mechanics, the value
              to Builders of launching through ToshiPad will continue to
              increase. As the market cap of TSHX increases, so does the
              incentive to launch through ToshiPad. This creates a continuously
              expanding market advantage for ToshiPad. In addition, we offer
              full service incubation including strategy, marketing,
              connectivity, legal, and funding, as well as a full ecosystem of
              waiting users. We anticipate accumulating a dominant share of the
              Ordinals launchpad market prior to the upcoming bullrun.
            </Typography>
            <Typography className={styles.desc}>
              Our core ethic however is not competitive, it is collaborative.
              Our mission is to bring more attention, capital, and
              cross-web3-Builders into Ordinals by providing professional tier
              tools and infrastructure to the Ordinals space.
            </Typography>
            <img
              src="https://i.ibb.co/pPrbhKs/roadmap.png"
              className={styles.banner}
              alt=""
            />
            <Typography className={styles.desc}>
              <h1
                style={{
                  fontWeight: "600",
                  color: "",
                }}
              >
                1️⃣ Phase 1: Platform Development and Launch (January 2024)
              </h1>
              <ul style={{ listStyle: "inside", paddingLeft: "10px" }}>
                <li>
                  <span style={{ fontWeight: "600" }}>
                    Initiation of platform development:
                  </span>
                  We kick off the development process, focusing on building the
                  core infrastructure and backend systems of BRC-20 DEX.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Integration of smart contracts:
                  </span>
                  Smart contracts are seamlessly integrated and rigorously
                  tested to enable secure and efficient trading operations on
                  the decentralized exchange.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    User interface design:{" "}
                  </span>{" "}
                  Our design team crafts an intuitive and user-friendly
                  interface, enhancing the trading experience and making it
                  accessible to users of all levels.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Security audits and testing:
                  </span>{" "}
                  Comprehensive security audits and rigorous testing procedures
                  are conducted to identify and address any vulnerabilities,
                  ensuring the platform's robustness and the protection of user
                  assets.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Fundraise on BRC-20 DEX:
                  </span>{" "}
                  We launch a fundraising campaign on the BRC-20 DEX, offering
                  investors the opportunity to participate in the project's
                  growth and success.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Beta testing and user feedback:
                  </span>{" "}
                  A select group of users is invited to participate in beta
                  testing, providing valuable feedback to improve the platform's
                  usability, functionality, and performance.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Optimization and refinement:
                  </span>{" "}
                  Continuous optimization and refinement take place based on
                  user feedback and testing results, enhancing the platform's
                  performance and addressing any identified areas for
                  improvement.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>Launch preparation:</span>{" "}
                  We finalize legal and regulatory requirements, establish
                  strategic partnerships, and develop marketing and
                  communication strategies to prepare for the official launch of
                  BRC-20 DEX.
                </li>
              </ul>
            </Typography>
            <Typography className={styles.desc}>
              <h1
                style={{
                  fontWeight: "600",
                  color: "",
                }}
              >
                2️⃣ Phase 2: Expansion and Launch of Fragmentation
              </h1>
              <h2>
                During this phase, we focus on expanding the capabilities of the
                BRC-20 DEX with fragmentation and preparing for its highly
                anticipated launch. Here are the key milestones:
              </h2>

              <ul style={{ listStyle: "inside", paddingLeft: "10px" }}>
                <li>
                  <span style={{ fontWeight: "600" }}>
                    Fragmentation Development:
                  </span>
                  Initiate the development of fragmentation features within the
                  BRC-20 DEX, enabling precise and granular token transactions.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Testing and Optimization:
                  </span>
                  Conduct rigorous testing and optimization to ensure seamless
                  integration and optimal performance of the fragmentation
                  functionality.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>Beta Testing:</span>
                  Invite users to participate in beta testing, specifically
                  targeting the fragmentation feature. Gather valuable feedback
                  to refine and enhance the user experience.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>Launch Preparation:</span>{" "}
                  Finalize preparations for the official launch of the
                  fragmentation feature, including legal and regulatory
                  compliance, security audits, and user education materials.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Fragmentation Feature Launch:
                  </span>
                  Officially launch the highly anticipated fragmentation
                  feature, providing users with fractional ownership and
                  enhanced precision in token transactions.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Community Engagement and Support:
                  </span>
                  Engage with the BRC-20 DEX community, offering support,
                  gathering feedback, and providing educational resources to
                  ensure a smooth transition to the new fragmentation era.
                </li>
              </ul>
            </Typography>

            <Typography className={styles.desc}>
              <h1
                style={{
                  fontWeight: "600",
                  color: "",
                }}
              >
                3️⃣ During Phase 3
              </h1>
              <h2>
                our focus is on further enhancing the functionality of the
                BRC-20 DEX and expanding its reach globally. Here are the key
                objectives and milestones for this phase:
              </h2>

              <ul style={{ listStyle: "inside", paddingLeft: "10px" }}>
                <li>
                  <span style={{ fontWeight: "600" }}>
                    Advanced Trading Features:
                  </span>
                  Introduce advanced trading features such as limit orders,
                  stop-loss orders, and margin trading to provide users with
                  more options and flexibility in their trading strategies.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Cross-Chain Compatibility:
                  </span>
                  Enable cross-chain compatibility, allowing users to trade
                  BRC-20 tokens across different blockchain networks, expanding
                  the liquidity and accessibility of the platform.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Global Market Expansion:
                  </span>
                  Initiate strategic partnerships and collaborations to expand
                  the reach of the BRC-20 DEX to new markets and regions
                  worldwide, providing access to a broader user base.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Enhanced Security Measures:
                  </span>{" "}
                  Continuously strengthen the security measures of the platform,
                  conducting regular security audits, and implementing advanced
                  security protocols to safeguard user assets.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Community Development:
                  </span>
                  Foster an active and engaged community by organizing events,
                  webinars, and educational programs to empower users with the
                  knowledge and skills to navigate the BRC-20 DEX effectively.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    User Feedback and Improvements:
                  </span>
                  Gather user feedback and insights to identify areas for
                  improvement and implement updates to enhance the overall user
                  experience and satisfaction.
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>
                    Regulatory Compliance:
                  </span>
                  Ensure compliance with relevant regulations and standards in
                  different jurisdictions to maintain a secure and compliant
                  trading environment for users.{" "}
                </li>
              </ul>
            </Typography>
          </>
        )}

        {slug === "brc20-zort" && (
          <>
            <Typography className={styles.mainTitle}>
              Roadmap: Future of ToshiPad
            </Typography>
            <Typography className={styles.desc}>
              Due to The Council Ecosystem’s spiral value mechanics, the value
              to Builders of launching through ToshiPad will continue to
              increase. As the market cap of TSHX increases, so does the
              incentive to launch through ToshiPad. This creates a continuously
              expanding market advantage for ToshiPad. In addition, we offer
              full service incubation including strategy, marketing,
              connectivity, legal, and funding, as well as a full ecosystem of
              waiting users. We anticipate accumulating a dominant share of the
              Ordinals launchpad market prior to the upcoming bullrun.
            </Typography>
            <Typography className={styles.desc}>
              Our core ethic however is not competitive, it is collaborative.
              Our mission is to bring more attention, capital, and
              cross-web3-Builders into Ordinals by providing professional tier
              tools and infrastructure to the Ordinals space.
            </Typography>
            <img
              src="https://i.ibb.co/pPrbhKs/roadmap.png"
              className={styles.banner}
              alt=""
            />
          </>
        )} */}
      </div>
    </>
  );
  // <div className={styles.roadmap}>
  // <Typography className={styles.mainTitle}>
  //   Roadmap: Future of ToshiPad
  // </Typography>
  //   <Typography className={styles.desc}>
  //     Due to The Council Ecosystem’s spiral value mechanics, the value to
  //     Builders of launching through ToshiPad will continue to increase. As the
  //     market cap of TSHX increases, so does the incentive to launch through
  //     ToshiPad. This creates a continuously expanding market advantage for
  //     ToshiPad. In addition, we offer full service incubation including
  //     strategy, marketing, connectivity, legal, and funding, as well as a full
  //     ecosystem of waiting users. We anticipate accumulating a dominant share of
  //     the Ordinals launchpad market prior to the upcoming bullrun.
  //   </Typography>
  //   <Typography className={styles.desc}>
  //     Our core ethic however is not competitive, it is collaborative. Our
  //     mission is to bring more attention, capital, and cross-web3-Builders into
  //     Ordinals by providing professional tier tools and infrastructure to the
  //     Ordinals space.
  //   </Typography>
  //   <img
  //     src="https://i.ibb.co/pPrbhKs/roadmap.png"
  //     className={styles.banner}
  //     alt=""
  //   />
  // </div>
};
