import { Alert, Button, Typography, Input, Slider } from "@mui/material";
import BigNumber from "bignumber.js";
import { BucketTypes, ModalTabs } from "constants/index";
import { useState, useEffect } from "react";
import { useProjectIdo } from "../../../../contexts/ProjectIdoContext";
import { satoshiFormat, SATOSHI_IN_BTC } from "../../../../utils/SatoshiFormat";

import styles from "./BucketConfirmation.module.scss";
import axios from "axios";
import { useWallet } from "contexts/WalletContext";

export const BucketConfirmation = (props: {
  setTab: any;
  amountSatoshi: string;
  selectedBucket: BucketTypes;
}) => {
  const {
    fee,
    confirmBucket,
    bucketConfirmed,
    BTCPrice,
    slug,
    id,
    price,
    maxMint,
    tier,
    allocation,
    totalMinted,
  } = useProjectIdo();
  const {
    walletData,
    setTransactionId,
    setTokensBought,
    setAdditionalFee,
    setChainFee,
    setFee,
    setOrderTotal,
  } = useWallet();
  const BTCPriceNumber = new BigNumber(BTCPrice);

  const tokensInDollar = BTCPriceNumber.times(
    new BigNumber(props.amountSatoshi).div(SATOSHI_IN_BTC)
  )
    .toFixed(2)
    .toString();

  const feeInDollar = BTCPriceNumber.times(
    new BigNumber(fee).div(SATOSHI_IN_BTC)
  )
    .toFixed(2)
    .toString();

  const total = new BigNumber(fee).plus(new BigNumber(props.amountSatoshi));

  const totalInDollar = BTCPriceNumber.times(total.div(SATOSHI_IN_BTC))
    .toFixed(2)
    .toString();

  const [tokens, setTokens] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [tokensTotal, setTokensTotal] = useState<number>(0);

  const [noTokens, setNoTokens] = useState<boolean>(false);
  const [limitExceeded, setLimitExceeded] = useState<boolean>(false);
  const [swr, setSwr] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleOtherPrices = () => {
    setTokensTotal(tokens * parseInt(price));
    setTotalPrice(tokens * parseInt(price) + parseInt(fee));
  };

  // useEffect(() => {}, [tokensTotal, totalPrice]);

  useEffect(() => {
    handleOtherPrices();
  }, [tokens]);

  const handleCreateOrder = async () => {
    //useState under the hood uses this.setState(() => {})
    setTokensBought(tokens);

    setNoTokens(false);
    setLimitExceeded(false);
    setSwr(false);
    if (tokens === 0 || isNaN(tokens)) {
      setNoTokens(true);
      return;
    }

    if (tokens > parseInt(maxMint)) {
      setLimitExceeded(true);
      return;
    }

    console.log(
      {
        user_wallet_address: walletData?.ordinalsAddress,
        amount: tokens.toString(),
        project_id: id,
      },
      "order req body"
    );

    props.setTab(ModalTabs.Payments);
    return;

    // try {
    //   console.log("create order called", props.amountSatoshi, total);
    //   const res = await axios.post(
    //     `${process.env.REACT_APP_BACKEND}/transactions`,
    //     {
    //       user_wallet_address: walletData?.ordinalsAddress,
    //       amount: tokens.toString(), //no of tokens to claim
    //       project_id: id,
    //       networkFee: 100,
    //     }
    //   );
    //   if (res.status === 201) {
    //     console.log(res.data.data, "order created OC");
    //     console.log(res.data.data.transaction, "transaction obj");
    //     console.log(res.data.data.charge, "charge obj");
    //     setTransactionId(res.data.data.transaction._id);
    //     setAdditionalFee(res.data.data.charge.additionalFee);
    //     setChainFee(res.data.data.charge.chainFee);
    //     setFee(res.data.data.charge.mempool_fee);
    //     setOrderTotal(res.data.data.charge.amount);
    //     console.log(res.data.data.transaction.createdAt, "transaction id");
    //     window.localStorage.setItem(
    //       "createdAt",
    //       res.data.data.transaction.createdAt
    //     );
    //     console.log(JSON.parse(res.data.data.transaction.charge), "charge obj");
    //     window.localStorage.setItem(
    //       "toshipad-invoice-info",
    //       res.data.data.transaction.charge
    //     );
    //     window.localStorage.setItem(
    //       "invoice_id",
    //       res.data.data.transaction.invoice_id
    //     );
    //   } else {
    //     setSwr(true);
    //     console.log(res.data.message, "order failed");
    //   }
    // } catch (err) {
    //   setSwr(true);
    //   console.error(err, "create order failed");
    // }
  };

  return (
    <div className={styles.section}>
      {noTokens && (
        <Alert
          severity="error"
          sx={{
            display: noTokens ? "flex" : "none",
            marginBottom: "20px",
          }}
        >
          Please select number of tokens
        </Alert>
      )}
      {limitExceeded && (
        <Alert
          severity="error"
          sx={{
            display: limitExceeded ? "flex" : "none",
            marginBottom: "20px",
          }}
        >
          You can only buy {maxMint} tokens
        </Alert>
      )}
      {swr && (
        <Alert
          severity="error"
          sx={{
            display: swr ? "flex" : "none",
            marginBottom: "20px",
          }}
        >
          Something went wrong
        </Alert>
      )}
      <Typography className={styles.title}>Select Quantity</Typography>
      <Typography className={styles.desc}>
        You will not be able to make that adjustment later in the process
      </Typography>

      <div className={styles.bottomInfos}>
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Tokens</Typography>
          <Typography className={styles.rightInfo}>
            <Input
              slot="input"
              type="number"
              disableUnderline
              placeholder="0"
              sx={{
                color: "white",
                width: "fit-content",
                maxWidth: "50px",
                display: "flex",
                alignSelf: "flex-end",
                marginRight: "0",
                textAlign: "right",
              }}
              onChange={(e) => setTokens(parseInt(e.target.value))}
              value={tokens}
            />
            {/* (${tokensInDollar}) */}
          </Typography>
        </div>
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>
            Max Tokens Per Mint: {maxMint}
          </Typography>
        </div>

        <Slider
          min={0}
          max={parseInt(maxMint)}
          size="small"
          defaultValue={tokens}
          value={tokens}
          aria-label="Small"
          valueLabelDisplay="auto"
          sx={{
            color: "#ff9900",
            width: "100%",
            alignSelf: "center",
            marginBottom: "20px",
          }}
          onChange={(event, newValue) => {
            if (!event) return;
            setTokens(newValue as number);
          }}
        />
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Tier</Typography>
          <Typography className={styles.rightInfo}>{tier} Tier</Typography>
        </div>
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Max Allocation</Typography>
          <Typography className={styles.rightInfo}>{allocation}</Typography>
        </div>
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>
            Tokens Left to Mint
          </Typography>
          <Typography className={styles.rightInfo}>{totalMinted}</Typography>
        </div>

        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Token Price</Typography>
          <Typography className={styles.rightInfo}>
            {satoshiFormat(price.toString())}

            {/* (${tokensInDollar}) */}
          </Typography>
        </div>

        <div className={styles.infoLine} />
        <div className={`${styles.oneInfo} ${styles.summary}`}>
          <Typography className={styles.leftInfo}>Order Total</Typography>
          <Typography className={styles.rightInfo}>
            {isNaN(tokensTotal)
              ? satoshiFormat("0")
              : satoshiFormat(tokensTotal.toString())}

            {/* {satoshiFormat(totalPrice.toString())} */}
            {/* (${totalInDollar}) */}
          </Typography>
        </div>
      </div>
      <div className={styles.bottomButtons}>
        <Button
          className={`${styles.btn}`}
          onClick={() => {
            //close modal here
          }}
        >
          <Typography>Back</Typography>
        </Button>

        <Button
          className={`${styles.btn} ${styles.rightBtn}`}
          onClick={() => {
            if (!bucketConfirmed) confirmBucket(props.selectedBucket);
            handleCreateOrder();
          }}
        >
          <Typography>Confirm</Typography>
        </Button>
      </div>
    </div>
  );
};
