import { Button, Typography } from "@mui/material";
import { Timer } from "components";

import styles from "./Card.module.scss";
import { Images } from "assets";
import { satoshiFormat, satoshiToBtcFormat } from "utils/SatoshiFormat";
import BigNumber from "bignumber.js";
import { btcToDollar } from "utils/BtcToDollar";
import { useEffect, useState } from "react";

const Card = (props: {
  id: any;
  banner: string;
  logo: string;
  tags: any;
  name: string;
  fundraise: string;
  allocation: string;
  isoTime: string;
  timerStatus: string;
  slug: string;
  price: string;
}) => {
  const [amountInDollar, setAmountInDollar] = useState<string>("");

  function convertSatsToBTC(amount: string) {
    console.log("convert sats to btc called");
    const sats = new BigNumber(amount);
    const btcAmount = satoshiToBtcFormat(sats);
    console.log(btcAmount, "btc amount");
    return btcAmount.toString().split(" ")[0];
  }

  async function convertSatsToUSD() {
    console.log("convert sats to usd called");
    const btc = convertSatsToBTC(props.fundraise.toString());
    const usd = await btcToDollar(btc);
    console.log(usd, "usd");
    setAmountInDollar(usd);
  }

  useEffect(() => {
    convertSatsToUSD();
  }, []);
  return (
    <div className={styles.card}>
      {/* {props.slug !== "brc20-zort" &&
      props.slug !== "BRC20Dex" &&
      props.slug !== "brc20-tstr" ? (
        <>
          <div className={styles.csBanner}>
            <img src={Images.comingSoon} alt="" />
          </div>
          <div className={styles.comingSoonBlock}>Coming Soon</div>
        </>
      ) : ( */}
      <>
        <div className={styles.banner}>
          <img src={props.banner ?? Images.toshipadLogo} alt="" />
        </div>

        <div className={styles.infos}>
          <div className={styles.logo}>
            <img src={props.logo ?? Images.aboutUs} alt="" />
          </div>
          <Typography className={styles.infoTitle}>{props.name}</Typography>
          <div className={styles.longInfo}>
            <Typography className={styles.infoLeft}>Fundraise Goal</Typography>
            <Typography className={styles.infoRight}>
              {/* {satoshiFormat(props.fundraise)} */}
              {props.slug === "brc20-zort"
                ? "$100,000"
                : props.slug === "BRC20Dex"
                ? "$20,000"
                : props.slug === "brc20-TSHX-claim"
                ? "Free Claim"
                : amountInDollar}
            </Typography>
          </div>
          {/* <div className={styles.longInfo}>
            <Typography className={styles.infoLeft}>Max allocation</Typography>
            <Typography className={styles.infoRight}>
              {props.allocation}
            </Typography>
          </div>
          <div className={styles.longInfo}>
            <Typography className={styles.infoLeft}>Max Ticket Size</Typography>
            <Typography className={styles.infoRight}>{"100"}</Typography>
          </div>
          <div className={styles.longInfo}>
            <Typography className={styles.infoLeft}>Price</Typography>
            <Typography className={styles.infoRight}>
              {satoshiFormat(props.price)}
            </Typography>
          </div> */}
          {/* {props.slug === "BRC20Dex" ? (
            <div className={styles.timer}>
              <div className={styles.tbd}>TBD</div>
            </div>
          ) : ( */}
          <div className={styles.timer}>
            <Timer isoTime={props.isoTime} timerStatus={props.timerStatus} />
          </div>
          {/* )} */}

          <div className={styles.tags}>
            {props.tags.map((element: string) => {
              return (
                <Typography className={styles.tag} key={element}>
                  {element}
                </Typography>
              );
            })}
          </div>
          <Button className={styles.cardBtn} href={`/project/${props.slug}`}>
            <Typography>See Details</Typography>
          </Button>
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export default Card;
