import { Button, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { Timer } from "components";
import { ModalType, useModal, useWallet } from "contexts";
import {
  ProjectIdoContext,
  UserFundingStatus,
  UserFundingStatusIcon,
  UserFundingStatusText,
} from "contexts/ProjectIdoContext";
import { useContext, useEffect, useMemo, useRef } from "react";
import { satoshiFormat, SATOSHI_IN_BTC } from "utils/SatoshiFormat";

import { Icons } from "../../../../assets";
import { Anchor } from "../../../../components/Anchor";
import { BucketTypes, ModalTabs } from "../../../../constants";
import { useState } from "react";
import { useProjectIdo } from "contexts/ProjectIdoContext";

import styles from "./RightTab.module.scss";
import is from "date-fns/locale/is/index.js";
import { set, setSeconds } from "date-fns";
import { AboutProject } from "launches/Gur1/components/aboutProject";
import { verifyCaptcha } from "services/captcha";
import ReCAPTCHA from "react-google-recaptcha";

export const RightTab = ({ project }: any) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { slug } = useProjectIdo();

  const [status, setStatus] = useState<string>("");
  const { openModal, closeModal } = useModal();
  const { lastTx, walletData, ready, setLastTx } = useWallet();
  const {
    totalRaised,
    raiseGoal,
    price,
    participants,
    fundingStatus,
    isTransactions,
    fee,
    bucketConfirmed,
    BTCPrice,
    Gold,
    Silver,
    Mercury,
    claimStatus,
    claimTokens,
    launchDate,
    notWhitelisted,
  } = useContext(ProjectIdoContext);

  const [isverified, setIsverified] = useState<boolean>(false);

  const BTCPriceNumber = new BigNumber(BTCPrice);
  const bucketValues = {
    [BucketTypes.Bronze]: Mercury,
    [BucketTypes.Silver]: Silver,
    [BucketTypes.Gold]: Gold,
  };

  const bucket: BucketTypes | null = fundingStatus?.bucketSize
    ? fundingStatus.bucketSize
    : null;

  const amountSatoshi = bucket ? bucketValues[bucket] : "0";
  const tokensInDollar = BTCPriceNumber.times(
    new BigNumber(amountSatoshi).div(SATOSHI_IN_BTC)
  )
    .toFixed(2)
    .toString();

  const receivedSatoshi = fundingStatus ? fundingStatus.deposit : "0";
  const receivedInDollar = BTCPriceNumber.times(
    new BigNumber(receivedSatoshi).div(SATOSHI_IN_BTC)
  )
    .toFixed(2)
    .toString();

  const total = new BigNumber(fee).plus(new BigNumber(amountSatoshi));

  const totalInDollar = BTCPriceNumber.times(total.div(SATOSHI_IN_BTC))
    .toFixed(2)
    .toString();

  // const dateToCount = project.isLive()
  //   ? project.registrationEndDateTime
  //   : project.isUpcoming()
  //   ? project.registrationStartDateTime
  //   : "";
  const dateToCount = "";

  const handleGetPaymentStatus = async () => {
    const invoice_id: string = window.localStorage.getItem("invoice_id")!;
    const paramsObj = {
      invoice_id,
    };

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND}/transactions?${new URLSearchParams(
        paramsObj
      ).toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();

    const parsedCharge = JSON.parse(data.data[0].charge);

    setStatus(parsedCharge.status);
    if (parsedCharge.status === "paid") {
      openModal(ModalType.PAYMENT, ModalTabs.TransactionSuccess);
    } else {
      openModal(ModalType.PAYMENT, ModalTabs.TransactionStatus);
    }
  };

  useEffect(() => {
    handleOneMinuteTimer();
  }, []);
  useEffect(() => {
    handleOneMinuteTimer();
  }, [closeModal]);

  const [secondsToShow, setSecondsToShow] = useState<number>(0);

  const [disableTimer, setDisableTimer] = useState<boolean>(false);

  useEffect(() => {}, [secondsToShow]);

  async function addMinutesToISODate(
    isoDateString: string,
    minutesToAdd: number
  ): Promise<string> {
    const dateObject = new Date(isoDateString);
    dateObject.setMinutes(dateObject.getMinutes() + minutesToAdd);
    return dateObject.toISOString();
  }

  function startCountdown(targetTime: string): void {
    const targetDate = new Date(targetTime).getTime();

    if (isNaN(targetDate)) {
      console.error("Invalid date format");
      return;
    }

    const intervalId = setInterval(() => {
      const currentDate = new Date().getTime();
      const timeDifference = targetDate - currentDate;

      if (timeDifference <= 0) {
        clearInterval(intervalId);
        isMoreThanOneMinuteFromGivenTime(
          window.localStorage.getItem("createdAt")!
        );
        window.localStorage.removeItem("createdAt");
        handleOneMinuteTimer();
        console.log("Countdown expired!");
        setDisableTimer(true);
        setSecondsToShow(0);
      } else {
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        /* tslint:disable:no-unused-variable */
        setSecondsToShow((prevSeconds) => {
          if (prevSeconds === seconds) {
            return prevSeconds;
          }
          return seconds;
        });
      }
    }, 1000);
  }

  function isMoreThanOneMinuteFromGivenTime(givenTime: string): boolean {
    const givenDateTime = new Date(givenTime);
    const currentDateTime = new Date();

    const timeDifference = currentDateTime.getTime() - givenDateTime.getTime();

    const isMoreThanOneMinute = timeDifference > 60 * 1000;

    if (isMoreThanOneMinute) {
      setLastTx("");
    }
    return isMoreThanOneMinute;
  }

  async function handleOneMinuteTimer() {
    if (window.localStorage.getItem("createdAt") === null) {
      setSecondsToShow(0);
      return;
    }
    if (
      !isMoreThanOneMinuteFromGivenTime(
        window.localStorage.getItem("createdAt")!
      )
    ) {
      const timeToCountDownTill = await addMinutesToISODate(
        window.localStorage.getItem("createdAt")!,
        1
      );

      startCountdown(timeToCountDownTill);
    }
  }

  const checkIfTheLaunchDateIsPassed = () => {
    const currentDate = new Date().getTime();
    const launchDateInMs = new Date(launchDate).getTime();
    if (currentDate > launchDateInMs) {
      return true;
    } else {
      return false;
    }
  };

  async function handleCaptchaSubmission(token: string | null) {
    console.log(token, "token from captcha");
    await verifyCaptcha(token)
      .then(() => setIsverified(true))
      .catch((err: any) => {
        console.error(err, "error from captcha");
        setIsverified(false);
      });
  }

  const renderButton = useMemo(() => {
    console.log(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      process.env.REACT_APP_RECAPTCHA_SECRET_KEY
    );
    if (!walletData) {
      if (ready) {
        return (
          <Button
            className={styles.bottomBtn}
            onClick={() => openModal(ModalType.WALLET_CONNECT)}
          >
            <Typography>Connect wallet</Typography>
          </Button>
        );
      }
      return null;
    }

    if (notWhitelisted) {
      return (
        <Button
          className={styles.bottomBtnDisabled}
          disabled
          onClick={() => openModal(ModalType.PAYMENT)}
        >
          <Typography>Not whitelisted</Typography>
        </Button>
      );
    }

    // if (!project.isLive() && !project.isCompleted()) {
    //   return null;
    // }

    if (calculatePercentageOfTotalRaisedTillNow() === "100") {
      return (
        <Button
          className={styles.bottomBtn}
          onClick={() => {
            window.open("https://unisat.io/market/brc20?tick=tshx", "_blank");
          }}
        >
          <Typography>Buy on Unisat</Typography>
        </Button>
      );
    }

    if (!checkIfTheLaunchDateIsPassed()) {
      return (
        <Button
          className={styles.bottomBtnDisabled}
          disabled
          onClick={() => openModal(ModalType.PAYMENT)}
        >
          <Typography>Whitelisted</Typography>
        </Button>
      );
    }

    if (isTransactions) {
      return (
        <div>
          <Button
            className={styles.bottomBtn}
            //work on opening a modal with transactions
            onClick={() => openModal(ModalType.TRANSACTIONS)}
          >
            <Typography>See Transactions</Typography>
          </Button>
          {window.localStorage.getItem("createdAt") === null ? (
            <div>
              {/* <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                ref={recaptchaRef}
                onChange={handleCaptchaSubmission}
              /> */}
              <Button
                className={styles.bottomBtn}
                // disabled={!isverified}
                onClick={() => openModal(ModalType.PAYMENT)}
              >
                <Typography>Buy Tokens</Typography>
              </Button>
            </div>
          ) : isMoreThanOneMinuteFromGivenTime(
              window.localStorage.getItem("createdAt")!
            ) ? (
            <div>
              {/* <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                ref={recaptchaRef}
                onChange={handleCaptchaSubmission}
              /> */}
              <Button
                className={styles.bottomBtn}
                // disabled={!isverified}
                onClick={() => {
                  openModal(ModalType.PAYMENT);
                }}
              >
                <Typography>Buy Tokens</Typography>
              </Button>
            </div>
          ) : disableTimer ? (
            <div>
              {/* <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                ref={recaptchaRef}
                onChange={handleCaptchaSubmission}
              /> */}
              <Button
                className={styles.bottomBtn}
                // disabled={!isverified}
                // type="submit"
                onClick={() => openModal(ModalType.PAYMENT)}
              >
                <Typography>Buy Tokens</Typography>
              </Button>
            </div>
          ) : (
            <Button
              className={styles.bottomBtnDisabled}
              disabled
              onClick={() => openModal(ModalType.PAYMENT)}
            >
              <Typography>
                Disabled until next {secondsToShow} seconds
              </Typography>
            </Button>
          )}
        </div>
      );
    } else {
      return (
        <>
          {checkIfTheLaunchDateIsPassed() ? (
            <div>
              {/* <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                ref={recaptchaRef}
                onChange={handleCaptchaSubmission}
              /> */}
              <Button
                className={styles.bottomBtn}
                // disabled={!isverified}
                onClick={() => openModal(ModalType.PAYMENT)}
              >
                <Typography>Buy Tokens</Typography>
              </Button>
            </div>
          ) : (
            <Button
              className={styles.bottomBtnDisabled}
              disabled
              onClick={() => openModal(ModalType.PAYMENT)}
            >
              <Typography>Whitelisted</Typography>
            </Button>
          )}
        </>
      );
    }
  }, [
    walletData,
    project,
    fundingStatus,
    ready,
    openModal,
    claimTokens,
    secondsToShow,
    isMoreThanOneMinuteFromGivenTime,
    disableTimer,
  ]);

  function calculatePercentageOfTotalRaisedTillNow() {
    const totalRaisedInNumber = Number(totalRaised);
    const raiseGoalInNumber = Number(raiseGoal);
    const percentage = (
      ((totalRaisedInNumber * +price) / raiseGoalInNumber) *
      100
    ).toPrecision(3);

    console.log(percentage, "percentage1");

    return percentage;
  }

  return (
    <div className={styles.rightTab}>
      <div className={styles.topInfos}>
        <div className={styles.topLeft}>
          <Typography className={styles.title}>{project.name}</Typography>
          <Typography className={styles.desc}>{project.tokenTicker}</Typography>
        </div>
        <Typography className={styles.topRight} data-value={""}>
          {""}
        </Typography>
      </div>
      {
        <div className={styles.timer}>
          <Timer isoTime={project.registrationStartDateTime} timerStatus={""} />
        </div>
      }
      {project.slug === "brc20-tshx-claim" ? (
        <></>
      ) : (
        <div className={styles.progress}>
          <div className={styles.top}>
            <div className={styles.left}>
              <Typography className={styles.progressTitle}>Progress</Typography>
            </div>
            <div className={styles.right}>
              <Typography className={styles.progressTitle}>
                {calculatePercentageOfTotalRaisedTillNow()}%
              </Typography>
            </div>
          </div>
          <div className={styles.progressBar}>
            <div
              className={styles.inside}
              style={{
                width: `${+calculatePercentageOfTotalRaisedTillNow()}%`,
              }}
            />
          </div>
        </div>
      )}

      <div className={styles.infos}>
        <div className={styles.oneInfo}>
          <Typography className={styles.left}>IDO Price</Typography>
          <Typography className={styles.right}>
            {/* {project.tokenPrice
              ? `1 ${project.tokenTicker} = ${satoshiFormat(
                  project.tokenPrice
                )}`
              : "TBA"} */}
            {price} sats
          </Typography>
        </div>
        <div className={styles.oneInfo}>
          <Typography className={styles.left}>Goal</Typography>
          <Typography className={styles.right}>
            {/* {satoshiFormat(project.raiseGoal)} */}
            {raiseGoal} sats
          </Typography>
        </div>
        <div className={styles.oneInfo}>
          <Typography className={styles.left}>Raised</Typography>
          <Typography className={styles.right}>
            {(parseInt(totalRaised) * parseInt(price)).toString()} sats
          </Typography>
        </div>
      </div>
      <div>{renderButton}</div>
    </div>
  );
};
