import CircularProgress from "@material-ui/core/CircularProgress";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import AOS from "aos";
import "aos/dist/aos.css";
import { ModalProvider, WalletProvider } from "contexts";
import { AboutUs, HomePage, Launchpad, Layout, Project } from "pages";
import { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import ScrollToTop from "./utils/ScrollToTop";

import theme from "styles/Theme";

import "./app.scss";
import { HowItWorks } from "pages/HowItWorks/HowItWorks";
import { Button } from "@material-ui/core";

const App = () => {
  const [ipAddress, setIPAddress] = useState("");
  const [blocked, setBlocked] = useState(true);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <ModalProvider>
        <WalletProvider>
        <ThemeProvider theme={theme}>
            <Router>
              <ScrollToTop />
              <Layout>
                <Suspense fallback={<CircularProgress />}>
                  <Switch>
                    <Route exact path="/">
                      <HomePage />
                    </Route>
                    <Route exact path="/about-us">
                      <AboutUs />
                    </Route>
                    {/* <Route exact path="/how-it-works">
                    <HowItWorks />
                  </Route> */}
                    <Route exact path="/projects">
                      <Launchpad />
                    </Route>
                    <Route exact path="/project/:projectSlug">
                      <Project />
                    </Route>

                    <Redirect to="/" />
                  </Switch>
                </Suspense>
              </Layout>
            </Router>
          </ThemeProvider>
        </WalletProvider>
      </ModalProvider>
    </>
  );
};

export default App;
