import { ProjectAbstract } from "ProjectAbstract";

import {
  CompletedSales,
  HeroSection,
  OngoingSales,
  Subscribe,
  UpcomingSales,
} from "./components";

import styles from "./components/LaunchpadCommon.module.scss";
import { useEffect, useState } from "react";
import axios from "axios";

export const Launchpad = () => {
  const [fetchedProjects, setFetchedProjects] = useState<any>([]);
  const [parsedProjects, setParsedProjects] = useState<ProjectAbstract[]>([]); //parsed projects from fetchedProjects
  const fetchAllProjects = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND}/projects`
    );
    console.log(data, "all projects");
    setFetchedProjects(data.data);
    const parsedP = data.data.slice(0, 3).map((project: any) => {
      const parsedProject: ProjectAbstract = {
        id: project._id,
        name: project.name,
        slug: project.slug,
        description: project.description,
        banner: project.image,
        registrationStartDateTime: project.launchDate,
        registrationEndDateTime: "",
        tokenPrice: project.price,
        tiers: Object.keys(project.bucketSize),
        tierValues: Object.values(project.bucketSize),
        tierTiming: project.tierTiming,
        maxAllocation: project.total_count,
        totalRaised: "",
        participants: "0",
        type: "",
        DescriptionComponent: project.about,
        RoadMapComponent: "",
        TokenomicsComponent: "",
        TeamComponent: "",
        tags: [],
        raiseGoal: "",
        tokenTicker: project.symbol,
        shortDescription: project.description,
        display_status: project.display_status,
      };
      return parsedProject;
    });
    setParsedProjects(parsedP);
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  useEffect(() => {}, [parsedProjects]);

  // const projects = Object.values(LaunchProjects);
  console.log(parsedProjects, "parsed projects");
  const projects = parsedProjects;
  const upcoming = projects.filter((project: ProjectAbstract) =>
    // project.isUpcoming()
    //add the logic here
    {
      const now = new Date();
      if (project.registrationStartDateTime) {
        const startDate = new Date(project.registrationStartDateTime);
        return now < startDate;
      }
      return false;
    }
  );
  const ongoing = projects.filter((project: ProjectAbstract) =>
    // project.isLive()
    {
      const now = new Date();
      if (
        project.registrationStartDateTime &&
        project.registrationEndDateTime
      ) {
        const startDate = new Date(project.registrationStartDateTime);
        const endDate = new Date(project.registrationEndDateTime);
        return now >= startDate && now <= endDate;
      }
      return false;
    }
  );
  const completed = projects.filter((project: ProjectAbstract) =>
    // project.isCompleted()
    {
      const now = new Date();
      if (project.registrationEndDateTime) {
        const endDate = new Date(project.registrationEndDateTime);
        return now > endDate;
      }
      return false;
    }
  );

  return (
    <div className={styles.mainPage}>
      <HeroSection />
      <OngoingSales projects={projects} />
      {/* <UpcomingSales projects={upcoming} /> */}
      {/* <CompletedSales projects={completed} /> */}
      <Subscribe />
    </div>
  );
};
