import { Button, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { BucketTypes, ModalTabs } from "constants/index";
import {
  ProjectContextType,
  ProjectIdoContext,
  useProjectIdo,
} from "contexts/ProjectIdoContext";
import { useContext, useEffect } from "react";
import { satoshiFormat, SATOSHI_IN_BTC } from "utils/SatoshiFormat";

import styles from "./Bucket.module.scss";
import { TierTimer } from "components/TierTimer";

export const Bucket = (props: {
  selectedBucket: BucketTypes;
  setSelectedBucket: any;
  setTab: any;
  setCloseWindow: any;
}) => {
  const projectApiData = useContext(ProjectIdoContext);
  const { tier, maxMint, launchDate, tiers, tierTiming, tierValues, ticker } =
    useProjectIdo();

  useEffect(() => {
    console.log(tierTiming, "tiers from");
  }, []);

  const BTCPriceNumber = new BigNumber(projectApiData.BTCPrice);

  const bronzeAllocation = BTCPriceNumber.times(
    new BigNumber(projectApiData[BucketTypes.Bronze]).div(SATOSHI_IN_BTC)
  ).toString();
  const silverAllocation = BTCPriceNumber.times(
    new BigNumber(projectApiData[BucketTypes.Silver]).div(SATOSHI_IN_BTC)
  ).toString();
  const goldAllocation = BTCPriceNumber.times(
    new BigNumber(projectApiData[BucketTypes.Gold]).div(SATOSHI_IN_BTC)
  ).toString();

  function computeClasses(t: any) {
    return t.toLowerCase() === tier.toLowerCase()
      ? `${styles.onePoint} ${styles.selectedPoint}`
      : `${styles.onePoint}`;
  }

  return (
    <div className={styles.bucket}>
      <Typography className={styles.title}>
        {"View your allocated tier"}
      </Typography>
      <div className={styles.tiers}>
        {tiers.map((t: any) => {
          console.log(tierTiming[t], "tiertiming");
          return (
            <div className={computeClasses(t)}>
              <div className={styles.tierBlock}>
                <div className={styles.left}>
                  <div className={styles.names}>
                    <Typography className={styles.leftTitle}>{t}</Typography>
                  </div>
                </div>
                <div className={styles.right}>
                  <Typography className={styles.rightTitle}>
                    {tierValues[t]} {ticker.toUpperCase()}
                  </Typography>
                </div>
              </div>
              <div className={styles.timerBlock}>
                <Typography className={styles.timerTitle}>
                  Time to Unlock
                </Typography>
                <TierTimer isoTime={tierTiming[t]} timerStatus={""} />
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.bottomButtons}>
        <Button
          className={`${styles.btn}`}
          onClick={() => {
            props.setCloseWindow(true);
          }}
        >
          <Typography>Close</Typography>
        </Button>
        <Button
          className={
            new Date(tierTiming[tier.toLowerCase() as any]) > new Date()
              ? styles.bottomBtnDisabled
              : `${styles.btn} ${styles.rightBtn}`
          }
          // onClick={() => {
          //   bucketConfirmed
          //     ? props.setTab(ModalTabs.Payments)
          //     : confirmBucket(props.selectedBucket)
          // }}
          onClick={() => {
            if (new Date(tierTiming[tier.toLowerCase() as any]) > new Date()) {
              return;
            }
            props.setTab(ModalTabs.BucketConfirmSelection);
          }}
        >
          <Typography>{"Next"}</Typography>
        </Button>
      </div>
    </div>
  );
};
