import { Typography } from "@mui/material";

import styles from "./AboutProject.module.scss";
import { useProjectIdo } from "contexts/ProjectIdoContext";
import Markdown from "react-markdown";
interface AboutProjectProps {
  data : string
}
export const AboutProject = ({data} : AboutProjectProps) => {
  const { slug } = useProjectIdo();
  console.log(slug);
  return (
    <div className={styles.aboutProject}>
      <Typography className={styles.mainTitle}>About Project</Typography>
      <Markdown>{data}</Markdown>
      {/* <>
        {slug == "brc20-zort" && (
          <>
            <Typography className={styles.desc}>
              ToshiPad serves as the launchpad and incubation platform for The
              Council Ecosystem. Unlike other launchpads within the BRC20 space,
              it distinguishes itself by prioritizing the enduring success of
              the projects it launches.
            </Typography>
            <Typography className={styles.desc}>
              The platform exclusively introduces projects with substantial
              long-term growth potential, ensuring their prosperity through
              dedicated and professional incubation and support services.
              Furthermore, each project that ToshiPad launches becomes an
              integral component of The Council Ecosystem, establishing
              interconnected value flows with other projects within the
              ecosystem.
            </Typography>
            <Typography className={styles.smallTitle}>Mechanics:</Typography>
            <Typography className={styles.desc}>
              ToshiPad is the exclusive launchpad for all projects launched
              through this incubation process. ToshiPad is powered by the BRC20
              token TSHX. TSHX entitles holders to different launchpad access
              tiers. All tiers are named after alchemical metals in holding with
              the overall mythos and culture of The Council.
            </Typography>
            <Typography className={styles.desc}>
              The higher the tier, the earlier access to token purchases and the
              larger the maximum allocations of tokens. Tiers will be granted to
              top holders of TSHX, in example, Gold Tier is available only to
              the top 30 holders of TSHX. Holder standing will be determined by
              unannounced snapshots prior to the launch of new announced
              projects.
            </Typography>
            <Typography className={styles.smallTitle}>
              💡 Public Sale Details and Strategy:
            </Typography>
            <Typography className={styles.desc}>
              Public Sale is 1.05M total TSHX. This is 20% of initial
              circulating supply. The remainder of circulating supply is
              claimable without charge to holders of prior products in our
              Ecosystem.
            </Typography>
          </>
        )}
        {slug == "BRC20Dex" && (
          <>
            <Typography className={styles.desc}>
              The BRC20 DEX Exchange is an innovative decentralized exchange
              built on the BRC20 protocol. In recent years, decentralized
              exchanges (DEX) have gained significant traction in the blockchain
              ecosystem due to their enhanced security, transparency, and user
              control over funds. The BRC20 DEX Exchange builds upon these
              principles and aims to provide a seamless and secure trading
              experience for BRC20 tokens.
            </Typography>
            <Typography className={styles.desc}>
              Spot & Leverage Trade on BRC-20 | DEX, Bridge & Launchpool
            </Typography>
            <Typography className={styles.desc}>
              ⭐️ Built on the innovative BRC-20 protocol, the BRC-20 DEX
              platform transforms accessibility and liquidity in high-value
              asset trading through fractional ordinals. Complementing these
              pioneering features, the BRC-20 DEX Exchange offers a diverse
              range of services, including spot and leverage trading, bridge to
              the BRC-20 network, and a BRC-20 projects launchpool.
            </Typography>
            <Typography className={styles.desc}>
              ✨ Key Investment Highlights:
            </Typography>
            <ul>
              <li>
                <Typography className={styles.desc}>
                  ➡️ Leverage Opportunities: BRC-20 DEX's Leverage Trading
                  allows traders to amplify their market positions with borrowed
                  funds, offering tools for risk management and real-time
                  monitoring to enhance trading potential and returns.
                </Typography>
              </li>
              <li>
                <Typography className={styles.desc}>
                  ➡️ Spot Trading: The exchange's zero-fee spot trading offers
                  significant advantages, such as cost savings from no
                  transaction fees, enabling more frequent trading and arbitrage
                  opportunities without impacting profits.
                </Typography>
              </li>
              <li>
                <Typography className={styles.desc}>
                  ➡️ Launchpool: The LaunchPool feature on the BRC-20 DEX
                  Exchange allows projects built on the BRC-20 protocol to debut
                  their tokens and raise funds directly through the platform.
                </Typography>
              </li>
              <li>
                <Typography className={styles.desc}>
                  ➡️ Bridge: BRC-20 DEX's Bridging Token Universes feature
                  connects various blockchain ecosystems, enabling seamless
                  token transfers and expanding access to diverse assets.
                </Typography>
              </li>
              <li>
                <Typography className={styles.desc}>
                  ➡️ 50% Revenue Distribution: Every holder of the BRC-20 token
                  automatically becomes eligible to receive 50% of the total
                  revenue generated by the platform.
                </Typography>
              </li>
            </ul>
            <Typography className={styles.desc}>🕯 Documents/Other:</Typography>
            <ul>
              <li>
                <Typography className={styles.desc}>
                 📃 Whitepaper
                  <a
                    href="https://brc-20-dex.gitbook.io/brc-20-dex-with-fragmentation/1.1-introduction"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;here
                  </a>
                </Typography>
              </li>
            </ul>
            <Typography className={styles.desc}>🔗 Links:</Typography>
            <ul>
              <li>
                <Typography className={styles.desc}>
                  🌐 Website{" "}
                  <a
                    href="https://brc-20dex.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </Typography>
              </li>
              <li>
                <Typography className={styles.desc}>
                  📊 Trade{" "}
                  <a
                    href="https://brc20-exchange.site/#/trade"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </Typography>
              </li>
              <li>
                <Typography className={styles.desc}>
                  📱 Telegram{" "}
                  <a
                    href="https://t.me/brc_20dex"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </Typography>
              </li>
              <li>
                <Typography className={styles.desc}>
                  🐦 Twitter{" "}
                  <a
                    href="https://x.com/Brc_20dex"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </Typography>
              </li>
            </ul>
            <Typography className={styles.desc}>
              ✅ Backers/Investors/Partners/Advisors:
            </Typography>
            <ul>
              <li>
                <Typography className={styles.desc}>
                  Evan Luthra{" "}
                  <a
                    href="http://linktr.ee/evanluthra"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </Typography>
              </li>
              <li>
                <Typography className={styles.desc}>
                  Wise Advice Sumit{" "}
                  <a
                    href="https://x.com/wiseadvicesumit?t=YFnChJD_tfAIWxrKGqq-dw&s=09"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </Typography>
              </li>
            </ul>
          </>
        )}
      </> */}
    </div>
  );
};
