import { Button, Typography, Skeleton } from "@mui/material";
import { Icons } from "assets";
import { ModalTabs } from "constants/index";
import { ModalType, useModal, useWallet } from "contexts";
import { useEffect, useState } from "react";
import { Anchor } from "components/Anchor";
import styles from "./Status.module.scss";
import { last } from "lodash-es";

export const Status = (props: { setTab: any }) => {
  const { closeModal } = useModal();
  const [status, setStatus] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { lastTx } = useWallet();

  const handleGetPaymentStatus = async () => {
    setLoading(true);

    const invoice_id: string = window.localStorage.getItem("invoice_id")!;
    const paramsObj = {
      invoice_id,
    };

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND}/transactions?${new URLSearchParams(
        paramsObj
      ).toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    console.log(data, "data from payment status");

    //instead use the status that backend sets
    const parsedCharge = data.data[0].status;
    console.log(parsedCharge, "parsed charge");
    setStatus(parsedCharge);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    handleGetPaymentStatus();
  }, []);

  const handleRefresh = async () => {
    await handleGetPaymentStatus();
  };

  useEffect(() => {}, [status, loading]);

  function shortenTransactionHash(
    hash: string,
    prefixLength = 3,
    suffixLength = 3
  ) {
    if (typeof hash !== "string" || hash.length < prefixLength + suffixLength) {
      return hash;
    }

    const prefix = hash.substring(0, prefixLength);
    const suffix = hash.substring(hash.length - suffixLength);

    return `${prefix}...${suffix}`;
  }

  return (
    <div className={styles.section}>
      <Typography className={styles.title}>Payment Status</Typography>
      <Typography className={styles.desc}>
        Payment Status:{" "}
        <Typography variant="inherit" className={styles.bold}>
          {loading ? (
            <Skeleton
              sx={{ bgcolor: "grey.900" }}
              variant="rectangular"
              width={110}
              height={18}
            />
          ) : (
            <>
              {status === "PAID"
                ? "PAYMENT INITIATED"
                : status === "PAID_FROM_ORDINALS"
                ? "PAYMENT CONFIRMED"
                : status === "PAID_TOO_LESS"
                ? "UNDERPAID"
                : status === "SUCCESS"
                ? "PAYMENT INITIATED"
                : "PAYMENT FAILED"}
            </>
          )}
        </Typography>
      </Typography>

      {lastTx && (
        <Typography
          className={styles.desc}
          onClick={() => {
            window.open(`https://mempool.space/tx/${lastTx}`, "_blank");
          }}
        >
          Transaction ID:
          <span className={styles.txnHash}>
            {shortenTransactionHash(lastTx)}
          </span>
        </Typography>
      )}

      <div className={styles.bottomButtons}>
        <Button
          className={`${styles.btn} ${styles.leftBtn}`}
          onClick={() => {
            // closeModal(ModalType.PAYMENT);

            props.setTab(ModalTabs.TransactionSummary);
          }}
        >
          <Typography>Back</Typography>
        </Button>
        <Button
          className={`${styles.btn} ${styles.rightBtn}`}
          onClick={() => {
            if (status === "paid") {
              closeModal(ModalType.PAYMENT);
              props.setTab(ModalTabs.TransactionSuccess);
              return;
            }
            handleRefresh();
          }}
        >
          <Typography>{status === "paid" ? "Close" : "Refresh"}</Typography>
        </Button>
      </div>
    </div>
  );
};
