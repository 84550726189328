import { Typography } from "@mui/material";

import styles from "./Team.module.scss";

export const Team = () => (
  <div className={styles.team}>
    <Typography className={styles.mainTitle}>Team</Typography>
    <Typography className={styles.smallTitle}>
      Members who support the ToshiPad Vision 👥
    </Typography>
    <Typography className={styles.desc}>
      Serge Ajamian: Head of Business Development and Growth Centauri Digital
      Asset Group, invested & advised over 100 projects
    </Typography>
    <Typography className={styles.desc}>
      Amalia Grochal: Head of Research and Development Managing Partner at Mirai
      Capital
    </Typography>
    <Typography className={styles.desc}>
      Sunny River: Head of Marketing Founder of BTC Culture - An opinion leader
      with hundreds of thousands follower
    </Typography>
    <Typography className={styles.desc}>
      Zamir Kakar: Head of Operations COO at ZogiLabs
    </Typography>
    <Typography className={styles.desc}>
      Nick Vadera: Technical Guru CEO of Zogi Labs
    </Typography>
    <Typography className={styles.desc}>
      Rishabh Java: Head of Development CEO and Founder of Paisa
    </Typography>
    <Typography className={styles.desc}>
      Patrick Wu: Legal Team Investments of SkyVision Capital
    </Typography>
    <Typography className={styles.smallTitle}>
      Backers and Partners 🏗
    </Typography>
    <Typography className={styles.desc}>
      Binance NFT, OKX, SkyVision Capital (SVC), Magic Eden, BTC Culture,
      Centauri Digital Asset Group, XVerse Wallet, BDE Ventures, LucidBlue
      Ventures, Ordz Games, Zogi Labs, New Tribe Capital
    </Typography>
  </div>
);
