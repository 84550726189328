import { Typography } from "@mui/material";

import styles from "./Tokenomics.module.scss";
import { Images } from "assets";
import { useProjectIdo } from "contexts/ProjectIdoContext";
import Markdown from "react-markdown";
import { ProjectAbstract } from "ProjectAbstract";
// import ReactMarkdown from 'react-markdown'

interface TokenomicsProps {
  data: string;
}
export const Tokenomics = ({ data }: TokenomicsProps) => {

  const { slug } = useProjectIdo();
  console.log(slug);
  //render markdown here
  return (
    <>
      <div className={styles.tokenomics}>
        <Typography className={styles.mainTitle}>Tokenomics</Typography>
        <Markdown>{data}</Markdown>
      </div>
      {/* {slug === "BRC20Dex" && (
        <>
          <div className={styles.tokenomics}>
            <Typography className={styles.mainTitle}>Tokenomics</Typography>
            <img src="https://i.ibb.co/zrcV7FY/image.png" alt="" />
            <img src="https://i.ibb.co/jM5vzjM/image.png" alt="" />
          </div>
        </>
      )}

      {slug === "brc20-zort" && (
        <>
          <div className={styles.tokenomics}>
            <Typography className={styles.mainTitle}>Tokenomics</Typography>
            <ul>
              <li>
                5 % - Community Treasury: Tokens reserved for use by future
                community DAO. May also be used to invest in the community.
              </li>
              <li>
                5 % - Public Sale: Available tokens for public sale to join the
                ecosystem.
              </li>
              <li>
                5 % - Team Growth Fund: Tokens available for talent acquisition
                and growth.
              </li>
              <li>
                15 % - Liquidity Provisions: Liquidity to ensure stability and
                viability of the ecosystem
              </li>
              <li>
                20 % - Ecosystem Rewards: Given back to the ecosystem as
                rewards.
              </li>
              <li>
                50 % - Ecosystem Growth: Tokens held for ecosystem growth
                including developer grants, partnerships, and shared value
                flows.
              </li>
            </ul>
            <img
              src={"https://i.ibb.co/ThDTr15/tokenomics.png"}
              className={styles.banner}
              alt=""
            />
          </div>
        </>
      )} */}
    </>
  );
};
