// import { base64 } from '@scure/base'
import { network } from "btcConfig";
import { useWallet } from "contexts";
// import * as btcSigner from '@scure/btc-signer'
import { signTransaction } from "sats-connect";
// import { getUTXOsService, UTXO } from 'services/userService'
import { WalletName } from "types";
import { sendBtcTransaction } from "sats-connect";

export const useSendBitcoin: any = () => {
  const { walletData, registeredUserData, setLastTx, networkFee } = useWallet();

  // TODO XVERSE send needs to be polished, current solution works, but do not build under next.js building script, as packages used are deprecated.
  // const bitcoinNet =
  //   network === 'Mainnet'
  //     ? {
  //         bech32: 'bc',
  //         pubKeyHash: 0x00,
  //         scriptHash: 0x05,
  //         wif: 0x80
  //       }
  //     : {
  //         bech32: 'tb',
  //         pubKeyHash: 0x6f,
  //         scriptHash: 0xc4,
  //         wif: 0xef
  //       }

  // const getLastUTXOAndBalance = (utxos: UTXO[]) => {
  //   const balance = utxos.reduce((acc, utxo) => acc + utxo.value, 0)

  //   let lastOutput = null
  //   if (utxos.length > 0) {
  //     lastOutput = {
  //       tx_hash: utxos[utxos.length - 1].txid,
  //       tx_output_n: utxos[utxos.length - 1].vout,
  //       value: utxos[utxos.length - 1].value
  //     }
  //   }
  //   return { balance, lastOutput }
  // }

  const createPSBT = async (
    paymentAddress: string,
    paymentPublic: string,
    recipient: string,
    amountToSend: number
  ) => {
    console.log(paymentAddress, paymentPublic, recipient, amountToSend);
    return "";
    // TODO XVERSE send needs to be polished, current solution works, but do not build under next.js building script, as packages used are deprecated.
    // const outputs = await getUTXOsService(paymentAddress)
    // const { lastOutput, balance } = getLastUTXOAndBalance(outputs)
    // if (lastOutput) {
    //   const tx = new btcSigner.Transaction()
    //   const publicKeyUint8Array = new Uint8Array(
    //     // @ts-ignore
    //     paymentPublic.match(/.{1,2}/g).map(byte => parseInt(byte, 16))
    //   )
    //   const p2wpkh = btcSigner.p2wpkh(publicKeyUint8Array, bitcoinNet)
    //   const p2sh = btcSigner.p2sh(p2wpkh, bitcoinNet)

    //   tx.addInput({
    //     txid: lastOutput.tx_hash,
    //     index: lastOutput.tx_output_n,
    //     witnessUtxo: {
    //       script: p2sh.script,
    //       amount: BigInt(lastOutput.value)
    //     },
    //     redeemScript: p2sh.redeemScript
    //   })

    //   tx.addOutputAddress(recipient, BigInt(amountToSend), bitcoinNet) // [0]
    //   tx.addOutputAddress(paymentAddress, BigInt(balance), bitcoinNet) // [1]

    //   const estimatedFee = tx.toPSBT(0).length * satVB

    //   const changeAmount = balance - amountToSend - estimatedFee
    //   tx.updateOutput(1, { amount: BigInt(changeAmount) })
    //   const psbt = tx.toPSBT(0)
    //   return base64.encode(psbt)
    // }
    // return ''
  };

  const sendBitcoin = async (amountSatoshi: string, addressToSend: string) => {
    let txId: string = "";
    console.log(amountSatoshi, "from sendbitcoin");
    const amountSat = Number(amountSatoshi);

    if (!walletData) return;

    if (walletData?.walletName === WalletName.Okx) {
      const okxwallet = (window as any).okxwallet;
      try {
        let txid = await okxwallet.bitcoin.sendBitcoin(
          addressToSend,
          amountSat,
          {
            feeRate: networkFee,
          }
        );
        console.log(txid);
        txId = txid;
      } catch (e) {
        console.log(e);
      }
    }

    if (walletData?.walletName === WalletName.Hiro) {
      const hiro = (window as any).btc;
      if (hiro) {
        const resp = await hiro.request("sendTransfer", {
          address: addressToSend,
          amount: amountSat,
          network: network.toLowerCase(),
          networkFee: networkFee,
        });
        txId = resp.result.txid;
      }
    }

    if (walletData?.walletName === WalletName.UniSat) {
      const unisat = (window as any).unisat;
      if (unisat) {
        try {
          console.log("sending bitcoin", addressToSend, amountSat, networkFee);
          const txid = await (window as any).unisat
            .sendBitcoin(addressToSend, amountSat, {
              feeRate: parseInt(networkFee.toString()),
            })
            .catch((e: any) => console.log(e));
          txId = txid;
        } catch (e) {
          console.log((e as any).message);
        }
      }
    }

    if (walletData?.walletName === WalletName.Xverse) {
      const amountForXverse = BigInt(amountSat);

      console.log(
        "address for xverse",
        addressToSend,
        amountSat,
        network,
        walletData?.paymentAddress
      );
      const sendBtcOptions: any = {
        payload: {
          network: {
            type: network,
          },
          recipients: [
            {
              address: addressToSend,
              amountSats: amountForXverse,
            },
          ],
          senderAddress: walletData?.paymentAddress,
          networkFee: networkFee,
        },
        onFinish: (response: any) => {
          alert(response);
          txId = response;
        },
        onCancel: () => alert("Canceled"),
      };

      await sendBtcTransaction(sendBtcOptions)
        .then((res) => {
          //res is empty
          console.log(res, "XVERSE");
          //parse txId from the response
        })
        .catch((e) => {
          console.log(e, "ERROR FROM XVERSE");
        });
    }
    setLastTx(txId);
  };
  return { sendBitcoin };
};
