import { Container } from "components";
import { ProjectAbstract } from "ProjectAbstract";

import { ProjectIdoProvider } from "../../../../contexts/ProjectIdoContext";
import { HeaderWithText } from "../HeaderWithText";
import { OngoingProjectCard } from "./OngoingProjectCard";

import styles from "../LaunchpadCommon.module.scss";
import { Projects } from "pages/Homepage/components";
import { useWallet } from "contexts";
import { useEffect, useState } from "react";
import Card from "pages/Homepage/components/projects/card/Card";
import axios from "axios";

type OngoingSalesProps = {
  projects: ProjectAbstract[];
};

export const OngoingSales: React.FunctionComponent<OngoingSalesProps> = ({
  projects,
}) => {
  console.log(projects, "projects");
  const { walletData } = useWallet();
  const [upcomingprojects, setUpcomingProjects] = useState<any>([]);
  const [fetchedProjects, setFetchedProjects] = useState<any>([]);
  const [parsedProjects, setParsedProjects] = useState<ProjectAbstract[]>([]); //parsed projects from fetchedProjects
  const fetchAllProjects = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND}/projects`
    );
    console.log(data, "all projects");
    setFetchedProjects(data.data);
    const parsedP = data.data.map((project: any) => {
      const parsedProject: ProjectAbstract = {
        id: project._id,
        name: project.name,
        slug: project.slug,
        description: project.description,
        tiers: Object.keys(project.bucketSize),
        tierValues: Object.values(project.bucketSize),
        tierTiming: project.tierTiming,
        banner: project.banner_url,
        registrationStartDateTime: project.launchDate,
        registrationEndDateTime: "",
        tokenPrice: project.price,
        maxAllocation: project.total_count,
        totalRaised: "",
        participants: "0",
        type: "",
        DescriptionComponent: "",
        RoadMapComponent: "",
        TokenomicsComponent: "",
        TeamComponent: "",
        tags: [],
        logo: project.logo_url,
        raiseGoal: (project.price * project.total_count).toString(),
        tokenTicker: project.symbol,
        shortDescription: project.description,
        display_status: project.display_status,
      };
      console.log(parsedProject.tiers, "tiers");
      return parsedProject;
    });
    setParsedProjects(parsedP);
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  useEffect(() => {
    cardsPropsParseUtil(parsedProjects);
  }, [parsedProjects]);

  // function convertDateFormat(inputDate: string): string {

  //   const originalDate = new Date(inputDate);

  //   const newDate = originalDate;

  //   newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());

  //   const formattedDate = newDate.toISOString().slice(0, -5) + "Z";

  //   return formattedDate;
  // }

  //function : fetch user tier

  // const fetchUserTier = async () => {
  //   const { data } = await axios.get(
  //     `${process.env.REACT_APP_BACKEND}/users`,
  //     {
  //       params: {
  //         wallet_address: walletData?.ordinalsAddress,
  //       },
  //     }
  //   );
  //   console.log(data, "user tier");
  // };

  // useEffect(() => {
  //   if (walletData?.ordinalsAddress) {
  //     fetchUserTier();
  //   }
  // }, []);

  function cardsPropsParseUtil(parsedProjects: ProjectAbstract[]) {
    const upcomingProjects: any = parsedProjects
      .filter((project) => project.display_status === "DEFAULT")
      .slice(0, 3)
      .map((project) => {
        const projectProps = {
          id: project.id,
          banner: project.banner,
          name: project.name,
          logo: project.logo,
          tags: [],
          fundraise: project.raiseGoal,
          isoTime: project.registrationStartDateTime,
          timerStatus: "",
          slug: project.slug,
          allocation: project.maxAllocation,
          price: project.tokenPrice,
          tiers: project.tiers,
          tierTiming: project.tierTiming,
        };
        return projectProps;
      });

    setUpcomingProjects(upcomingProjects);
  }

  return (
    <div className={styles.projects}>
      <h1 className={styles.topTitle}>Upcoming Projects</h1>
      <div className={styles.top}>
        {upcomingprojects.map((project: any, index: number) => (
          <Card key={index} {...project} />
        ))}
      </div>
    </div>
  );
};
