import { Button, Typography } from "@mui/material";
import { Icons } from "assets";
import BigNumber from "bignumber.js";
import { BucketTypes, ModalTabs, PaymentMethod } from "constants/index";
import { useWallet } from "contexts";
import { useSendBitcoin } from "hooks";
import { useEffect, useState } from "react";

import { useProjectIdo } from "../../../../contexts/ProjectIdoContext";
import {
  satoshiFormat,
  satoshiToBtcFormat,
  SATOSHI_IN_BTC,
} from "../../../../utils/SatoshiFormat";

import styles from "./Summary.module.scss";
import axios from "axios";
import { btcToDollar } from "utils/BtcToDollar";

export const Summary = (props: {
  setTab: any;
  paymentMethod: PaymentMethod | null;
  setTxId: any;
  amountSatoshi: string;
  selectedBucket: BucketTypes;
}) => {
  const { sendBitcoin } = useSendBitcoin();
  const {
    walletData,
    registeredUserData,
    lastTx,
    transaction_id,
    networkFee,
    tokensBought,
    additionalFee,
    chainFee,
    fee,
    orderTotal,
  } = useWallet();
  const { BTCPrice, price } = useProjectIdo();
  const BTCPriceNumber = new BigNumber(BTCPrice);
  const [addressToSendSats, setAddressToSendSats] = useState("");
  const [amountToSend, setAmountToSend] = useState(0);

  const tokensInDollar = BTCPriceNumber.times(
    new BigNumber(props.amountSatoshi).div(SATOSHI_IN_BTC)
  )
    .toFixed(2)
    .toString();

  const feeInDollar = BTCPriceNumber.times(
    new BigNumber(fee).div(SATOSHI_IN_BTC)
  )
    .toFixed(2)
    .toString();

  const total = new BigNumber(fee).plus(new BigNumber(props.amountSatoshi));

  const totalInDollar = BTCPriceNumber.times(total.div(SATOSHI_IN_BTC))
    .toFixed(2)
    .toString();

  const [amountInDollar, setAmountInDollar] = useState<string>("");

  const handleMarkTransactionAsPaid = async () => {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND}/transactions/markTransactionAsPaid`,
      {
        txn_hash: lastTx,
        transaction_id,
      }
    );
    console.log(res, "mark as paid");
  };

  useEffect(() => {
    if (lastTx != "") {
      handleMarkTransactionAsPaid();
      props.setTab(ModalTabs.TransactionStatus);
      return;
    }
    if (window.localStorage.getItem("toshipad-invoice-info")) {
      const invoiceObj: any = window.localStorage.getItem(
        "toshipad-invoice-info"
      )!;
      console.log("invoice info found1", JSON.parse(invoiceObj));
      setAddressToSendSats(JSON.parse(invoiceObj).address);
      setAmountToSend(JSON.parse(invoiceObj).amount);
    }
  }, [lastTx, props]);

  useEffect(() => {}, [addressToSendSats, amountToSend]);

  useEffect(() => {}, [props]);

  const handleCopy = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  function convertSatsToBTC(amount: string) {
    console.log("convert sats to btc called");
    const sats = new BigNumber(amount);
    const btcAmount = satoshiToBtcFormat(sats);
    console.log(btcAmount, "btc amount");
    return btcAmount.toString().split(" ")[0];
  }

  async function convertSatsToUSD() {
    console.log("convert sats to usd called");
    const btc = convertSatsToBTC(orderTotal.toString());
    const usd = await btcToDollar(btc);
    console.log(usd, "usd");
    setAmountInDollar(usd);
  }

  useEffect(() => {
    convertSatsToUSD();
  }, []);

  //TODO needs to be fixed, once XVERSE send BTC will work on prod
  return (
    <div className={styles.section}>
      <Typography className={styles.title}>Transaction Summary</Typography>
      <div className={styles.bottomInfos}>
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Token Price</Typography>
          <Typography className={styles.rightInfo}>
            {satoshiFormat(price)}
          </Typography>
        </div>
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Order Quantity</Typography>
          <Typography className={styles.rightInfo}>
            {tokensBought} Tokens
          </Typography>
        </div>
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Total Token Price</Typography>
          <Typography className={styles.rightInfo}>
            {additionalFee ? additionalFee.toString() : 0} sats
          </Typography>
        </div>
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Chain Fee</Typography>
          <Typography className={styles.rightInfo}>
            {satoshiFormat(chainFee.toString())}
          </Typography>
        </div>

        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Service Fee</Typography>
          <Typography className={styles.rightInfo}>
            {satoshiFormat(fee.toString())}
          </Typography>
        </div>
        <div className={styles.oneInfo}>
          <Typography className={styles.leftInfo}>Network Fee</Typography>
          <Typography className={styles.rightInfo}>
            {networkFee} sats/vB
          </Typography>
        </div>
        <div className={styles.infoLine} />
        <div className={`${styles.oneInfo} ${styles.summary}`}>
          <Typography className={styles.leftInfo}>Order Total</Typography>
          <Typography className={styles.rightInfo}>
            {satoshiFormat(orderTotal.toString())} {`(${amountInDollar})`}
          </Typography>
        </div>
      </div>
      <div className={styles.bottomButtons}>
        <Button
          className={`${styles.btn}`}
          onClick={() => {
            props.setTab(ModalTabs.Payments);
          }}
        >
          <Typography>Back</Typography>
        </Button>
        {props.paymentMethod === PaymentMethod.ConnectedWallet ? (
          <Button
            className={`${styles.btn} ${styles.rightBtn}`}
            onClick={() => {
              sendBitcoin(orderTotal.toString(), addressToSendSats.toString());
              //sendBitcoin(
              //  orderTotal.toString(),
              //  "bc1pa035zlnd9tpc3f500nmsjpejvqfwtmjd09r370rvswjwr3zrzmvq7fwm3d"
              //);
            }}
          >
            <Typography>Pay with {walletData!.walletName}</Typography>
          </Button>
        ) : (
          <Button
            className={`${styles.btn} ${styles.rightBtn}`}
            onClick={() => {
              props.setTab(ModalTabs.TransactionStatus);
            }}
          >
            <Typography>Paid</Typography>
          </Button>
        )}
      </div>
      {/* <div className={styles.warning}>
        <Typography>
          Please be advised that currently our cryptocurrency services and
          products are not available to U.S. Customers. We take regulatory
          compliance seriously and strive to operate within the bounds of
          applicable laws and regulations. By proceeding with a transaction, you
          are accepting the terms of service including the geographic
          restrictions listed above
        </Typography>
      </div> */}
    </div>
  );
};
